import { types } from '../../../src/store/types/types';
import { portal } from '../../assets/plugins/axios/axios.js';
import { roleEnum } from '../../enums/roleEnum';
import { groupTypes } from '../../enums/groupTypes';
import { errorSubCodeTypes } from '../../enums/errorSubCodeTypes';

const state = {
  selectedProfile: {},
  additionalData: [],
  contactInfo: [],
  rightToBeForgottenRequests: [],
};

const getters = {
  [types.GET_SELECTED_PROFILE]: state => state.selectedProfile,
  [types.GET_PROFILE_ADDITIONAL_DATA]: state => state.additionalData,
  [types.GET_CONTACT_INFO]: state => state.contactInfo,
  [types.GET_RTBF_REQUESTS]: state => state.rightToBeForgottenRequests,
};

const mutations = {
  [types.MUTATE_SELECT_PROFILE]: (state, payload) => {
    state.selectedProfile = payload;
  },
  [types.MUTATE_LOAD_PROFILE_ADDITIONAL_DATA]: (state, payload) => {
    state.additionalData = payload;
  },
  [types.MUTATE_RESET_SELECTED_PROFILE]: (state, payload) => {
    state.selectedProfile = payload;
  },
  [types.MUTATE_CONTACT_INFO]: (state, payload) => {
    state.contactInfo = payload;
  },
  [types.MUTATE_LOAD_RTBF_REQUESTS]: (state, payload) => {
    state.rightToBeForgottenRequests = payload;
  },
};

const actions = {
  [types.SELECT_PROFILE]: ({ commit }, payload) => {
    if (payload == null) {
      commit(types.MUTATE_SELECT_PROFILE, {});
    } else if (payload.instProfileIds != null) {
      return portal
        .get('?method=profiles.getProfileMasterData', {
          params: {
            instProfileIds: payload.instProfileIds,
            fromAdministration: payload.fromAdministration,
          },
        })
        .then(response => commit(types.MUTATE_SELECT_PROFILE, response.data.data))
        .catch(error => {
          if (error.response?.data.status.subCode !== errorSubCodeTypes.deactivatedInstitutionProfile) {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PROFILE_MASTER_DATA');
          }
          return Promise.reject(error);
        });
    }
  },
  [types.UPDATE_PROFILE]: ({ commit }, { institutionProfilesMasterData, primaryInstitutionProfileId }) =>
    portal
      .post('?method=profiles.updateProfileMasterData', {
        institutionProfilesMasterData,
        primaryInstitutionProfileId,
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_PROFILE_MASTER_DATA');
      }),
  [types.LOAD_CONTACT_INFO]: ({ commit }, payload) =>
    portal
      .get('?method=profilesAdmin.getUserData', {
        params: payload,
      })
      .then(response => commit(types.MUTATE_CONTACT_INFO, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PROFILE_MASTER_DATA');
      }),
  [types.LOAD_PROFILE_ADDITIONAL_DATA]: ({ commit }, payload) =>
    portal
      .get('?method=profiles.getAdditionalDataResponsesByInstitutionProfileIds', {
        params: { instProfileIds: payload.instProfileIds },
      })
      .then(response => commit(types.MUTATE_LOAD_PROFILE_ADDITIONAL_DATA, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ADDITIONAL_DATA');
      }),
  [types.RESET_SELECTED_USER]: ({ commit }) => {
    commit(types.MUTATE_SELECT_PROFILE, {});
  },
  [types.EDIT_BLOCK_OF_COMMUNICATION_FOR_SELECTED_USER]: ({ commit }, payload) => {
    if (payload == null) {
      commit(types.MUTATE_SELECT_PROFILE, {});
    } else {
      portal
        .post('?method=roleAdministration.' + (payload.state ? 'setdenyroles' : 'deletedenyroles'), {
          roles: [
            {
              assignedProfileId: payload.id,
              roleDefinition: { roleDefinitionId: roleEnum.COMMUNICATION },
            },
          ],
        })
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_DENY_ROLES');
        });
    }
  },
  [types.DELETE_USER_DATA]: ({ commit, state }, payload) => {
    const errorMsg = [];
    const promises = [];
    const documentIds = [];
    let promise;
    const selectedInstProfile =
      state.selectedProfile.institutionProfiles != null && state.selectedProfile.institutionProfiles.length
        ? state.selectedProfile.institutionProfiles[0]
        : {};
    if (payload) {
      for (const id of payload.selectedIds) {
        const item = payload.allItems.find(item => item.itemId == id);
        if (item != null) {
          if (item.type === 'uni-profile') {
            if (errorMsg.indexOf('stam') === -1) {
              errorMsg.push('stam');
            }
          } else if (item.type === 'aula-profile') {
            const payload = {
              profileId: item.object.profileId,
              institutionProfileId: selectedInstProfile.id,
            };
            if (item.property === 'home-phone') {
              payload.deleteHomePhoneNumber = true;
            } else if (item.property === 'work-phone') {
              payload.deleteWorkPhoneNumber = true;
            } else if (item.property === 'mobile-phone') {
              payload.deleteMobilePhoneNumber = true;
            } else if (item.property === 'email') {
              payload.deleteEmail = true;
            } else if (item.property === 'description') {
              payload.deleteDescription = true;
            } else if (item.property === 'uni-photo') {
              payload.deleteExternalProfilePicture = true;
            } else if (item.property === 'photo') {
              payload.deleteInternalProfilePicture = true;
            }

            promise = portal.post('?method=profilesAdmin.deleteProfileMasterData', payload).catch(() => {
              commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
            });
            promises.push(promise);
          } else if (item.type === 'consents') {
            promise = portal
              .post('?method=consentsAdmin.deleteConsentResponseAnswersByInstitutionProfileId', {
                institutionProfileId: selectedInstProfile.id,
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });
            promises.push(promise);
          } else if (item.type === 'additional-data') {
            promise = portal
              .post('?method=profilesAdmin.deleteAdditionalDataResponsesByInstitutionProfileIds', {
                institutionProfileIds: [selectedInstProfile.id],
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });
            promises.push(promise);
          } else if (item.type === 'group' && item.object.groupType !== groupTypes.UNI) {
            promise = portal
              .post('?method=groups.deleteGroupMemberships', {
                groupId: item.object.groupId,
                institutionProfileId: selectedInstProfile.id,
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });
            promises.push(promise);
          } else if (item.type === 'group' && item.object.groupType === groupTypes.UNI) {
            if (errorMsg.indexOf('uni') === -1) {
              errorMsg.push('uni');
            }
          } else if (item.type === 'post') {
            promise = portal
              .post('?method=postsAdmin.deletePost', {
                id: item.object.id,
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });

            promises.push(promise);
          } else if (item.type === 'document') {
            documentIds.push(item.object.id);
          } else if (item.type === 'event') {
            promise = portal
              .post('?method=calendarAdmin.deleteEvent', {
                id: item.object.id,
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });

            promises.push(promise);
          } else if (item.type === 'subscription') {
            promise = portal
              .post('?method=messagingAdmin.deleteThreads', {
                threadIds: [item.object.id],
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });

            promises.push(promise);
          } else if (item.type === 'invitation') {
            promise = portal
              .post('?method=calendar.removeAttendeeFromEvent', {
                eventId: item.object.id,
                instProfileId: selectedInstProfile.id,
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });

            promises.push(promise);
          } else if (item.type === 'media') {
            promise = portal
              .post('?method=galleryAdmin.deleteMedia', {
                mediaIds: [item.object.id],
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });
            promises.push(promise);
          } else if (item.type === 'comment') {
            promise = portal
              .post('?method=commentsAdmin.hardDeleteComment', {
                id: item.object.id,
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });
            promises.push(promise);
          } else if (item.type === 'pickup') {
            promise = portal
              .post('?method=presenceAdmin.deleteAllPickUpNames', {
                uniStudentId: item.object.uniStudentId,
              })
              .catch(() => {
                commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
              });
            promises.push(promise);
          }
        }
      }
    }
    if (documentIds.length > 0) {
      promise = portal
        .post('?method=documentsAdmin.deleteDocuments', {
          documentIds: documentIds,
        })
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_USER_DATA');
        });

      promises.push(promise);
    }

    return {
      promises: promises,
      errorMsg: errorMsg,
    };
  },
  [types.HANDLE_ENTITIES_RTBF]: ({ commit }, payload) =>
    portal
      .post('?method=municipalConfigurationAdmin.handleEntities', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'RTBF_HANDLE_ENTITIES_SUCCESS');
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_HANDLE_ENTITIES_ADMIN');
      }),
  [types.LOAD_RTBF_REQUESTS]: ({ commit }, payload) =>
    portal
      .get('?method=municipalConfigurationAdmin.getRightToBeForgottenRequests', { params: payload })
      .then(response => commit(types.MUTATE_LOAD_RTBF_REQUESTS, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_RIGHT_TO_BE_FORGOTTEN_REQUESTS');
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
