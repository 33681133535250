export enum iconClassEnum {
  PRINTER = 'icon-printer',
  ADVANCED_SEARCH = 'icon-aula-advanced-search',
  BELL = 'icon-Bell',
  BELL_2 = 'icon-Bell_2',
  BUILDING_HOUSE = 'icon-building-house',
  PHONE_2 = 'icon-phone-2',
  PRESENCE_CHECK = 'icon-presence-check',
  WALKING_PERSON = 'icon-walking-person',
  BACKPACK = 'icon-backpack',
  DOWN_DOUBLE_ARROW = 'icon-aula-down-double-arrow',
  UP_DOUBLE_ARROW = 'icon-aula-up-double-arrow',
  SLEEP = 'icon-sleep',
  ROTATE_LEFT = 'icon-Aula_rotate_left',
  ROTATE_RIGHT = 'icon-Aula_rotate_right',
  CHECKIND_NEUTRAL = 'icon-Checkind_neutral',
  SPEECHBOBBEL = 'icon-speechbobbel',
  KOMMEGAA_IKON = 'icon-Kommegaa_ikon',
  FERIE_IKON = 'icon-Ferie_ikon',
  SYG_IKON = 'icon-Syg_ikon',
  DOWN_TRIANGLE = 'icon-down-triangle',
  GOOGLEDRIVE = 'icon-googledrive',
  ONEDRIVE = 'icon-onedrive',
  MOBILE = 'icon-Aula_mobile',
  MINUS = 'icon-minus',
  PLUS = 'icon-plus',
  DOWNLOAD = 'icon-Aula_download',
  YOUTUBE = 'icon-youtube',
  YOUTUBE2 = 'icon-youtube2',
  MICROPHONE = 'icon-Aula-microphone',
  PLAY_BUTTON = 'icon-Aula_play_button',
  CONVERSATION = 'icon-Aula_conversation',
  ALBUM = 'icon-Album',
  TAG = 'icon-tag',
  SFO_ANIMALS = 'icon-Aula_sfo_animals',
  SFO_BOOKS1 = 'icon-Aula_sfo_books1',
  SFO_CYKLING = 'icon-Aula_sfo_cykling',
  SFO_FIRE = 'icon-Aula_sfo_fire',
  SFO_FOOTBALL = 'icon-Aula_sfo_football',
  SFO_GYMNASTIC_HALL = 'icon-Aula_sfo_gymnastic_hall',
  SFO_HAMMER = 'icon-Aula_sfo_hammer',
  SFO_ICE_SKATING = 'icon-Aula_sfo_ice_skating',
  SFO_LIBRARY = 'icon-Aula_sfo_library',
  SFO_PAINT_BRUSHES = 'icon-Aula_sfo_paint_brushes',
  SFO_PENS = 'icon-Aula_sfo_pens',
  SFO_SCISSORS = 'icon-Aula_sfo_scissors',
  SFO_SKATEBOARDING = 'icon-Aula_sfo_skateboarding',
  SFO_SLIDE = 'icon-Aula_sfo_slide',
  SFO_SPORTS_HALL = 'icon-Aula_sfo_sports_hall',
  SFO_SWIMMING = 'icon-Aula_sfo_swimming',
  SFO_SWINGS = 'icon-Aula_sfo_swings',
  SFO_THEATER = 'icon-Aula_sfo_theater',
  APP_ARROW_SLIDEBACK = 'icon-Aula_app_arrow_slideback',
  AULA_BELL = 'icon-Aula_bell',
  SKOLETUBE = 'icon-Aula_SkoleTube',
  COMPUTER = 'icon-Aula_Computer',
  INFORMATION = 'icon-Aula_Information',
  QUESTIONMARK = 'icon-Aula_questionmark',
  HOUSE = 'icon-Aula_house',
  EDIT_PEN = 'icon-Aula_edit_pen',
  TEXTEDITOR_NUMBERS = 'icon-texteditor_numbers',
  CLOUD = 'icon-Aula_cloud',
  GROUP = 'icon-Aula_group',
  BACK_TRIANGLE = 'icon-Aula_back-triangle',
  BRUGER_ADMIN = 'icon-Aula_bruger_admin',
  CHECK_BOX = 'icon-Aula_check-box',
  CHECK_CIRCLE = 'icon-Aula_check-circle',
  DOWN_ARROW = 'icon-Aula_down-arrow',
  EMAIL = 'icon-Aula_email',
  FORWARD_ARROW = 'icon-Aula_forward-arrow',
  FORWARD_TRIANGLE = 'icon-Aula_forward-triangle',
  LOCK = 'icon-Aula_lock',
  OPSTNING_ADMIN = 'icon-Aula_opstning_admin',
  UP_ARROW = 'icon-Aula_up-arrow',
  WATCH = 'icon-Aula_watch',
  TEXTEDITOR_ADJUST_CENTER = 'icon-texteditor_adjust_center',
  TEXTEDITOR_ADJUST_LEFT = 'icon-texteditor_adjust_left',
  TEXTEDITOR_ADJUST_RIGHT = 'icon-texteditor_adjust_right',
  TEXTEDITOR_ATTACH = 'icon-texteditor_attach',
  TEXTEDITOR_BOLD = 'icon-texteditor_bold',
  TEXTEDITOR_BULLETS = 'icon-texteditor_bullets',
  TEXTEDITOR_CAMERA = 'icon-texteditor_camera',
  TEXTEDITOR_EMOJI = 'icon-texteditor_Emoji',
  TEXTEDITOR_HIGHLIGHT_COLOUR = 'icon-texteditor_highlight_colour',
  TEXTEDITOR_ITALICS = 'icon-texteditor_italics',
  TEXTEDITOR_LETTERS = 'icon-texteditor_letters',
  TEXTEDITOR_LINETHROUGH = 'icon-texteditor_linethrough',
  TEXTEDITOR_QUOTE = 'icon-texteditor_quote',
  TEXTEDITOR_TEXT_COLOUR = 'icon-texteditor_text_colour',
  TEXTEDITOR_UNDERLINE = 'icon-texteditor_underline',
  ARROW_NEW_ITEM = 'icon-Aula_arrow_new_item',
  CHECK = 'icon-Aula_check',
  HOME = 'icon-Aula_home',
  MUTE = 'icon-Aula_mute',
  PHONE = 'icon-Aula_phone',
  SETTINGS_ADMIN = 'icon-Aula_settings_admin',
  STAR_OUTLINE = 'icon-Aula_star_outline',
  STAR_SOLID = 'icon-Aula_star_solid',
  USER = 'icon-Aula_user',
  USER_ADMIN = 'icon-Aula_user_admin',
  CLOSE = 'icon-Aula_close',
  FOLDER = 'icon-Aula_folder',
  AULA_PLUS = 'icon-Aula_plus',
  ATTACH_DOC = 'icon-Aula_attach_doc',
  BIN = 'icon-Aula_bin',
  REDIRECT = 'icon-Aula_redirect',
  SETTINGS = 'icon-Aula_settings',
  STAR = 'icon-Aula_star',
  BACK_ARROW = 'icon-Aula_back-arrow',
  CALENDAR = 'icon-Aula_calendar',
  CLOCK = 'icon-Aula_clock',
  ELIPSES = 'icon-Aula_elipses',
  EXPORT = 'icon-Aula_export',
  HAMBURGER = 'icon-Aula_hamburger',
  LINK = 'icon-Aula_link',
  NOTE = 'icon-Aula_note',
  PAPER = 'icon-Aula_paper',
  PICTURE = 'icon-Aula_picture',
  PROFILE = 'icon-Aula_profile',
  SEARCH = 'icon-Aula_search',
  WRITE = 'icon-Aula_write',
  LOGO = 'icon-Logo',
  LOGO_1 = 'icon-Logo-1',
  LOGO_2 = 'icon-Logo-2',
  LOGO_3 = 'icon-Logo-3',
  LOGO_4 = 'icon-Logo-4',
  BLACK_CIRCLE = 'icon-Aula_black_circle',
  EXCLAMATION_SIGN = 'icon-Aula_exclamation_sign',
  FILE_PICTURE = 'icon-file-picture',
  FILE_MUSIC = 'icon-file-music',
  FILE_PLAY = 'icon-file-play',
  FILE_VIDEO = 'icon-file-video',
  ENVELOPE_CLOSED = 'icon-Aula_envelope_closed',
  ENVELOPE_OPEN = 'icon-Aula_envelope_open',
  PUBLISH = 'icon-Aula_publish',
  UNPUBLISH = 'icon-Aula_unpublish',
  DOWN_ARROW_2 = 'icon-Aula_down-arrow-2',
  UP_DOWN_ARROW_2 = 'icon-Aula_up-down-arrow-2',
  UP_ARROW_2 = 'icon-Aula_up-arrow-2',
  WIDGET = 'icon-Aula_widget',
  USER_ADD = 'icon-Aula_user-add',
  USER_LEAVE = 'icon-Aula_user-leave',
  HANDLE_BAR = 'icon-Aula_handle-bar',
  PERSONAL_DATA = 'icon-Aula_personal-data',
  REPLY = 'icon-Aula_reply',
  RESTORE = 'icon-Aula_restore',
  REPLY_ALL = 'icon-Aula_reply-all',
}
