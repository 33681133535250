export enum dateFormatEnum {
  COMPLETE_DATE = 'YYYY-MM-DD',
  COMPLETE_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  FULL_DATE = 'dddd DD. MMMM YYYY',
  SHORT_DATE_WITH_WEEKDAY = 'dddd [d.] D[.] MMM[.]',
  SHORT_TIME = 'HH:mm',
  SHORT_DATE = 'DD. MMM. YYYY',
  SHORT_DATE_WITHOUT_LEADING_ZERO = 'D. MMM. YYYY',
  SHORT_DATE_TIME = 'DD. MMM. YYYY HH:mm',
  LONG_DATE_TIME_WITH_DATE_PREFIX = '[d.] D. MMM[.] YYYY [kl.] LT',
  LONG_DATE_TIME = 'D. MMM[.] YYYY [kl.] LT',
  DATE_PICKER_FORMAT = 'dd/MM/yyyy',
  DATE_PICKER_VALUE_FORMAT = 'yyyy-MM-dd',
  COMPACT_DATE_TIME = 'DD-MM-YYYY HH:mm',
}
