// SHARED
export const SEARCHING_LABEL = 'Søger';
export const DONE_LABEL = 'Færdig';
export const SELECT_INSTITUTION_HEADER = 'Institution';
export const SELECT_INSTITUTION_TEXT = 'Vælg den institution som du vil administrere';
export const SELECT_INSTITUTION_DESCRIPTION = 'Vælg institutionen, du vil tilmeldes med.';
export const SELECT_INSTITUTION_DESCRIPTION_APPLY_GROUP = 'Vælg institutionen, du vil ansøge med.';
export const REPLY_APPLY_GROUP = 'Ansøg';
export const BACK_TO_AULA = 'Tilbage til Aula';
export const ADMINISTRATION_SITE = 'administration';
export const BIRTHDAY = 'Fødselsdag';
export const UNKNOW = 'Ukendt';
export const SEARCH_LABEL = 'SØG';
export const SEARCH_LABEL_CHILD = 'Søg';
export const GLOBAL_SEARCH_PLACEHOLDER = 'Søg efter indhold i Aula';
export const SEARCH_FILTER_LABEL = 'FILTRER';
export const SEARCH_FILTER_LABEL_MOBILE = 'Filtrer søgning';
export const SEARCH_FILTER_ALL_LABEL = 'Alle';
export const SEARCH_FILTER_GROUPS_LABEL = 'Grupper';
export const SEARCH_FILTER_INTERNAL_DOCUMENTS_LABEL = 'Sikre dokumenter og noter';
export const SEARCH_FILTER_EXTERNAL_DOCUMENTS_LABEL = 'Sikre importerede filer';
export const SEARCH_FILTER_COMMON_FILES_LABEL = 'Fælles filer';
export const SEARCH_FILTER_PROFILES_LABEL = 'Personer';
export const SEARCH_FILTER_CHILDREN_LABEL = 'Børn';
export const SEARCH_FILTER_GUARDIANS_LABEL = 'Forældre';
export const SEARCH_FILTER_EMPLOYEES_LABEL = 'Ansatte';
export const SEARCH_FILTER_MESSAGES_LABEL = 'Beskeder';
export const SEARCH_FILTER_EVENTS_LABEL = 'Begivenheder';
export const SEARCH_FILTER_MEDIA_LABEL = 'Medier';
export const SEARCH_FILTER_POSTS_LABEL = 'Opslag';
export const SEARCH_CREATOR_BY_LABEL = 'Oprettet af';
export const SEARCH_CREATED_LABEL = 'Oprettet';
export const SEARCH_CATEGORY_LABEL = 'Kategori';
export const SEARCH_ASSOCIATION_LABEL = 'Omhandler';
export const SEARCH_DESC_LABEL = 'Beskrivelse';
export const SEARCH_RESULT_LABEL = 'Søgeresultat';
export const SEARCH_RESULT_MOBILE_LABEL = 'Du har søgt på ';
export const SEARCH_RESULT_JOIN_GROUP = 'Tilmeld gruppe';
export const SEARCH_RESULT_LEAVE_GROUP = 'Forlad gruppe';
export const SEARCH_RESULT_APPLY_GROUP = 'Ansøg gruppe';
export const SEARCH_RESULT_CANCEL_GROUP_APPLICATION = 'Fortryd ansøgning';
export const SEARCH_RESULT_MESSAGE_BY = 'Af';
export const SEARCH_RESULT_MESSAGE_TYPE = 'Type:';
export const SEARCH_RESULT_MESSAGE_THREADMESSAGE = 'Beskedtråd';
export const SEARCH_RESULT_MESSAGE_DATE = '- d. ';
export const SEARCH_RESULT_NOT_SPECIFIED = 'Ikke angivet';
export const SEARCH_RESULT_PROFILE_GUARDIAN_ROLE = 'Forælder';
export const SEARCH_RESULT_PROFILE_EMPLOYEE_ROLE = 'Medarbejder';
export const SEARCH_RESULT_PROFILE_EMPLOYEE_ROLE_OTHER = 'Andet';
export const SEARCH_RESULT_PROFILE_EMPLOYEE_ROLE_TEACHER = 'Lærer';
export const SEARCH_RESULT_PROFILE_EMPLOYEE_ROLE_PRESCHOOL_TEACHER = 'Pædagog';
export const SEARCH_RESULT_PROFILE_EMPLOYEE_ROLE_LEADER = 'Leder';
export const SEARCH_RESULT_TOASTR_JOINED_GROUP = 'Du er nu medlem af gruppen';
export const SEARCH_RESULT_TOASTR_APPLIED_GROUP = 'Du har ansøgt medlemskab af gruppen';
export const SEARCH_RESULT_NO_RESULT = 'Din søgning gav ikke noget resultat. Prøv evt. et andet søgeord';
export const SEARCH_RESULT_NO_SEARCH_TERM_HEADER = 'Søgning';
export const SEARCH_RESULT_NO_SEARCH_TERM_BODY = 'Indtast søgeord for at foretage en søgning.';
export const SEARCH_SENSITIVE_STEP_UP_TITLE = 'For at se søgeresultater med følsomme data';
export const SEARCH_SENSITIVE_STEP_UP_CONTENT = 'Log ind på højere sikkerhedsniveau';
export const LOAD_MORE_LOWERCASE = 'flere';
export const BADGE_COUNT_NAVIGATE_TO = 'Der er {count} notifikationer, naviger til {destination}';
export const NAVIGATE_TO = 'Naviger til ';
export const NAVIGATE_MORE_LINKS = 'Fold ekstra menupunkter ud';
export const LABEL_ATTACHMENTS = 'Attachments';
export const NAVIGATE_TO_FRONTPAGE = 'Gå til startsiden';
export const GALLERY_MEDIA_REACH_LIMIT_NUMBER = 'Du kan ikke uploade mere end';
export const GALLERY_MEDIA_REACH_LIMIT_NUMBER_2 = 'fil(er)';
export const ATTACHMENT_MIN_SIZE_FAILING = 'Filen indeholder ikke noget indhold og du kan derfor ikke vedhæfte den.';
export const ATTACHMENT_SIZE_CAUTION = 'ADVARSEL';
export const DOCUMENTS_TYPES_GOOGLE_FILES_TITLE = 'Google Drev';
export const DOCUMENTS_TYPES_ONEDRIVE_FILES_TITLE = 'OneDrive';
export const DOCUMENTS_TYPES_GOOGLE_FILES_TITLE_LINK = 'Link til fil i Google Drev';
export const DOCUMENTS_TYPES_ONEDRIVE_FILES_TITLE_LINK = 'Link til fil i OneDrive';
export const DATA_POLICY_CHANGES = 'Ændringer i privatlivspolitik tekst';
export const GALLERY_DOWNLOAD_ERROR =
  'Download kunne ikke gennemføres da en eller flere medier ikke længere eksisterer';

export const ATTACH_GOOGLE_FILES_TITLE = 'Vedhæft fil fra Google Drev';
export const ATTACH_ONEDRIVE_FILES_TITLE = 'Vedhæft fil fra  OneDrive';

export const LINK_OR_ATTACH_EXTERNAL_TOOLTIP_HEADER = 'Bemærk';
export const LINK_OR_ATTACH_EXTERNAL_TOOLTIP_BODY =
  'Ved at klikke på dette link vil du blive viderestillet til en ekstern service.';
export const LINK_OR_ATTACH_EXTERNAL_TOOLTIP_MOBILE_CONFIRM = 'Er du sikker på, at du vil fortsætte?';

export const FILTER_CLOSE_LABEL = 'Luk';
export const FILTER_SHOW_CONTENT = 'Vis indhold';
export const FILTER_SELECT_PROFILE = 'Vælg et bosted';
export const FILTER_MAXIMUM_INSTITUTIONS_DESCRIPTION = 'Få vist indhold fra max 6 institutioner';
export const FILTER_MAXIMUM_CHILDREN_DESCRIPTION = 'Få vist indhold fra max 6 børn';

export const ARIA_LABEL_PROFILE_FILTER = 'Vælg profil, {number} valgt';

export const MY_INSTITUTIONS_LABEL = 'INSTITUTIONER';
export const SELECTED_INSTITUTIONS = 'Valgte institutioner';
export const ADD_INSTITUTION = 'Tilføj institution';
export const MY_CHILDREN_LABEL = 'BØRN';
export const USERS_LABEL_CHILDREN = 'Børn';
export const USERS_LABEL_SETTINGS = 'INDSTILLINGER';
export const USERS_LABEL_NOTIFICATION_SETTINGS = 'Notifikationer';
export const DELETE_NOTIFICATION_SUCCEED_TOASTR = 'Notifikationer for de valgte områder blev ryddet';
export const USERS_LOGOUT = 'Log ud';
export const SWITCH_PROFILE = 'Skift bruger til ';
export const SWITCH_USER = ' SKIFT PROFIL';
export const OTHER_PROFILES = 'Øvrige';
export const ALL_PROFILES = 'Alle';
export const DESELECT_PROFILE = 'Fravælg profil';

export const CLOSE_MODAL = 'Luk Modal vindue';
export const CLOSE_PROFILE_LIST = 'Luk profil liste';

// PROFILE
export const USERS_LABEL_PROFILE = 'Profil';
export const USERS_LABEL_EMAIL = 'E-mail (kontakt)';
export const USERS_LABEL_EMAIL_AULA = 'Aula-e-mail';
export const USERS_LABEL_TELEPHONE = 'Telefon';
export const USERS_LABEL_ADDRESS = 'Adresse';

export const INSTITUTION_ROLE_CHILD = 'Barn';
export const INSTITUTION_ROLE_EMPLOYEE = 'Medarbejder';
export const INSTITUTION_ROLE_GUARDIAN = 'Forælder';
export const INSTITUTION_ROLE_OTP = 'Officielt tilknyttet person';
export const INSTITUTION_ROLE_DAYCARE = 'Dagtilbud';
export const INSTITUTION_ROLE_EARLYSTUDENT = 'Indskolingselev';
export const INSTITUTION_ROLE_MIDDLELATESTUDENT = 'Mellemskoleelev';
export const INSTITUTION_ROLE_PRESCHOOLTEACHER = 'Pædagog';
export const INSTITUTION_ROLE_OTHER = 'Andet';
export const INSTITUTION_ROLE_TEACHER = 'Lærer';
export const INSTITUTION_ROLE_LEADER = 'Leder';

export const ALL_CHILDREN = 'Alle børn';
export const BOYS = 'Drenge';
export const GIRLS = 'Piger';
export const GROUP_HOMES = 'Andre';

// TABLE
export const TABLE_DESELECT_ALL_FILTERS = 'Fravælg alle';
export const TABLE_EMPTY_FILTERED_TEXT = 'Der er ingen resultater der matcher din søgning';
export const TABLE_SEARCH_PLACEHOLDER = 'Søg i tabellen';
export const TABLE_EMPTY_TEXT = 'Der er ingen data at vise';
export const TABLE_DATE_FILTER_TITLE = 'Filtrer på dato';
export const TABLE_DATE_FILTER_START = 'Fra:';
export const TABLE_DATE_FILTER_END = 'Til:';
export const TABLE_LINK_TO_MEDIA = 'Link til Medie';
export const TABLE_SELECT_ALL_FILTERS = 'Vælg alle';
export const TABLE_SELECT_PAGE_OPTIONS = 'resultater pr. side';
export const TABLE_SELECT_SHOW_PAGE_OPTIONS = 'Viser';

// COOKIE BANNER
export const COOKIE_BANNER_OK = 'OK';
export const COOKIE_BANNER_READ_MORE_POLICY = 'Læs mere om vores cookie politik';
export const COOKIE_BANNER_POLICY_0 =
  'For at sikre os at indholdet på aula.dk er relevant og til at finde rundt i, benytter vi os af cookies. Cookies giver os vigtige informationer om, hvordan vores side bliver brugt, hvilke sider der bliver set mest, hvor længe vores brugere bliver på siderne osv.';
export const COOKIE_BANNER_POLICY_1_1 = 'Hvad er en cookie?';
export const COOKIE_BANNER_POLICY_1_2 =
  'En cookie er en lille tekstfil, der lagres i din browser for at kunne genkende din computer ved tilbagevendende besøg. Cookies er ikke aktive filer, de kan altså ikke udvikle virus eller spore indhold på din computer. Det eneste, de gør, er at sende anonyme oplysninger tilbage til os om fx besøgstidspunkt, varighed osv.';
export const COOKIE_BANNER_POLICY_2_1 = 'Hvis du ikke vil tillade cookies';
export const COOKIE_BANNER_POLICY_2_2 =
  'Hvis du ikke vil tillade brugen af cookies på din computer, kan du ændre i indstillingerne i din browser, så den husker det fremover. Du kan også slette cookies, der allerede er lagret. Se vejledning og læs mere om cookies her:';
export const COOKIE_BANNER_POLICY_3_1 = 'Brug af personoplysninger';
export const COOKIE_BANNER_POLICY_3_2 =
  'Personoplysninger bliver på intet tidspunkt videregivet eller solgt til tredjepart og vi indsamler ikke personoplysninger, uden du selv har givet os disse (fx gennem dit Samtykke).';
export const COOKIE_BANNER_POLICY_4_1 = 'Hvilke Cookies bruger vi';
export const COOKIE_BANNER_POLICY_4_2 =
  'Vi bruger cookies til at identificere din brugersession inkl. loginmetode på tværs af sideskift, og til at sikre at Aula vises korrekt. Disse cookies gemmes i maksimalt et år. Der er ikke tale om tredjeparts-cookies.';
export const COOKIE_BANNER_NO_THANKS = 'Nej tak til statistik ved at klikke her.';
export const COOKIE_BANNER_MESSAGE_1 = 'Vi samler statistik ved hjælp af cookies';
export const COOKIE_BANNER_MESSAGE_2 = 'Du accepterer, hvis du klikker OK. Du kan sige';

// ALERTS
export const ALERT_EXPIRES = 'Udløber';
export const ALERT_SEE_MORE = 'Se mere her.';
export const ALERT_READ_MORE = 'Læs mere her.';
export const ALERT_GO_TO_EVENT = 'Gå til begivenhed her';
export const ALERT_SURVEY_DECLINE = 'Jeg ønsker ikke at deltage';
export const ALERT_SURVEY_ACCEPT = 'Deltag';
export const ALERT_REPLY = 'Tilmeld';
export const ALERT_FROM = 'Fra';
export const ALERT_VACATION_RESPONSE_REQUIRED = 'Ferieperiode:';
export const ALERT_TO = 'til';
export const ALERT_AND_ENDS = 'og slutter';
export const ALERT_DATE = 'd.';
export const ALERT_REPEAT_EVERY = 'Begivenheden finder sted hver';
export const ALERT_REPEAT_DAY = 'dag';
export const ALERT_REPEAT_WEEK = 'uge';
export const ALERT_REPEAT_MONTH = 'måned d.';
export const ALERT_CANCELLED = 'Aflyst';
export const ALERT_IS_CANCELLED = 'er blevet aflyst';
export const ALERT_CANCELLED_BY = 'er blevet aflyst af ';
export const ALERT_BY = 'af ';
export const ALERT_DELETED = 'er slettet';
export const ALERT_VACATION_REQUEST = 'Ferieanmodningen';
export const ALERT_RIGHTS_GRANTED_1 = 'Du har fået rettigheder til at administrere ';
export const ALERT_RIGHTS_GRANTED_2 = 's kalender';
export const ALERT_RIGHTS_LOST_1 = 'Du har ikke længere rettigheder til at administrere ';
export const ALERT_RIGHTS_LOST_2 = 's kalender';
export const ALERT_ROOM_LOST_1 = 'Lokalet';
export const ALERT_ROOM_LOST_2 = 'er ikke længere til rådighed for dig, idet skemaplanlægningen har booket det.';
export const ALERT_RESPOND_ON_BEHALF = 'har svaret for dig om du deltager i begivenheden';
export const ALERT_REMOVED_TIMESLOT_RESPONSE = 'har slettet din tidsreservation til begivenheden';
export const ALERT_TYPE_POST_WAS_DELETED = 'Dit opslag';
export const ALERT_POST_WAS_DELETED = 'er blevet slettet';
export const ALERT_POST_WAS_REMOVED_FROM_GROUP_BY_ADMIN = '" er blevet fjernet fra gruppen';
export const ALERT_RESOURCE_HAS_BOOKED_TEXT1 = 'Du har ikke længere booket ';
export const ALERT_RESOURCE_HAS_BOOKED_TEXT2 = ' til ';
export const ALERT_RESOURCE_HAS_BOOKED_TEXT3 = ', fordi det er blevet booket i skemaplanlægningssystemet.';
export const ALERT_RESOURCE_HAS_BOOKED_TEXT4 = 'Book ny ressource her.';
export const ALERT_SEE_ALL_BUTTON = 'Se alle notifikationer';
export const ALERT_SEE_LESS_BUTTON = 'Se færre notifikationer';
export const ALERT_JOIN_OR_LEAVE_GROUP = 'Du prøver at forlade en gruppe du ikke er medlem af';
export const ALERT_GROUP_IS_DEACTIVATED_1 = 'Gruppen/Grupperne';
export const ALERT_GROUP_IS_DEACTIVATED_2 = 'er deaktiveret og skal fjernes før du kan gemme';
export const ALERT_COMMON_INBOX_SHARED = 'Du er blevet tilføjet til fællespostkassen';
export const ALERT_REMOVED_FROM_COMMON_INBOX = 'Du har ikke længere adgang til fællespostkassen';
export const ALERT_SUBSTITUTE_EVENT_TEXT1 = 'Vikarlektion:';
export const ALERT_SUBSTITUTE_EVENT_TEXT2 = 'for';
export const ALERT_GO_TO_LESSON_TEXT = 'Se lektionen her.';
export const ALERT_EVENT_IS_DELETED = 'Begivenheden {title}, d. {triggered} er blevet slettet af {cancelledBy}';
export const ALERT_EVENT_IS_EDITED = 'Begivenheden {title}, d. {triggered} er blevet ændret af {updatedBy}';
export const ALERT_SINGLE_OCCURRENCE_IS_DELETED = 'Begivenheden {title} er blevet aflyst.';
export const ALERT_DEFAULT_NOTIFICATION_CONTENT_WITH_END_TIME = 'Fra d. {startDate} til {endDate} kl. {endTime}';
export const ALERT_DEFAULT_NOTIFICATION_CONTENT_WITHOUT_END_DATE = 'Fra d. {startDate}';
export const ALERT_REPEATED_EVENT_NOTIFICATION_CONTENT =
  'Fra d. {startDateTime} - {endTime} - Begivenheden finder sted hver {repeatingPattern}';
export const ALERT_REPEATED_EVENT_NOTIFICATION_AND_END_AT = ' og slutter d. {endDate}.';
export const ALERT_RESOURCE_RESERVATION_DECLINED = 'Afslået reservation';

// TOAST INFO MESSAGES
export const SUCCESS_TOAST_LESSON_UPDATE = 'Dine ændringer er gemt';
export const SUCCESS_TOAST_PARENTAL_MEETING_CREATE = 'Forældremødet blev oprettet';
export const SUCCESS_TOAST_PARENTAL_MEETING_UPDATE = 'Forældremødet blev opdateret';
export const SUCCESS_TOAST_PARENTAL_MEETING_DELETE = 'Forældremødet blev slettet';
export const SUCCESS_TOAST_SCHOOL_HOME_MEETING_CREATE = 'Skole-hjem-samtalen blev oprettet';
export const SUCCESS_TOAST_SCHOOL_HOME_MEETING_UPDATE = 'Skole-hjem-samtalen blev opdateret';
export const SUCCESS_TOAST_SCHOOL_HOME_MEETING_DELETE = 'Skole-hjem-samtalen blev slettet';
export const SUCCESS_TOAST_PERFORMANCE_MEETING_CREATE = 'MUS-samtalen blev oprettet';
export const SUCCESS_TOAST_PERFORMANCE_MEETING_UPDATE = 'MUS-samtalen blev opdateret';
export const SUCCESS_TOAST_PERFORMANCE_MEETING_DELETE = 'MUS-samtalen blev slettet';
export const SUCCESS_TOAST_EVENT_UPDATE = 'Begivenheden blev opdateret';
export const SUCCESS_TOAST_EVENT_CREATE = 'Begivenheden blev oprettet';
export const SUCCESS_TOAST_EVENT_DELETE = 'Begivenheden blev slettet';
export const SUCCESS_TOAST_VACATION_REGISTRATION_DELETE = 'Ferieanmodningen blev slettet';
export const SUCCESS_TOAST_VACATION_REGISTRATION_CREATE = 'Ferieanmodningen blev oprettet';
export const SUCCESS_TOAST_VACATION_REGISTRATION_UPDATE = 'Ferieanmodningen blev opdateret';
export const SUCCESS_TOAST_INVITATIONS_ACCEPTED = 'Du har accepteret begivenheden';
export const SUCCESS_TOAST_INVITATIONS_DECLINED = 'Du har afvist begivenheden';
export const SUCCESS_TOAST_INVITATIONS_TENTATIVE = 'Du har besvaret begivenheden';
export const SUCCESS_TOAST_INVITATIONS_CHANGED = 'Svar opdateret';
export const SUCCESS_TOAST_MESSAGES_ADDED_RECIPIENT = 'Modtageren blev tilføjet';
export const SUCCESS_TOAST_MESSAGES_ADDED_RECIPIENTS = 'Modtagerne blev tilføjet';
export const SUCCESS_TOAST_MESSAGES_LEAVE_THREAD = 'Du har forladt samtalen';
export const SUCCESS_TOAST_MESSAGES_DELETE_THREADS_FROM_NORMAL_FOLDER = 'Besked tråden er slettet';
export const SUCCESS_TOAST_MESSAGES_FORWARD = 'Beskeden blev videresendt';
export const SUCCESS_TOAST_MESSAGES_RESPOND_SINGLE_MESSAGE = 'Beskeden blev sendt';
export const SUCCESS_TOAST_MESSAGES_AUTOREPLY_CREATED = 'Autosvaret blev oprettet';
export const SUCCESS_TOAST_MESSAGES_AUTOREPLY_DELETED = 'Autosvaret blev slettet';
export const SUCCESS_TOAST_MESSAGES_MUTED = 'Notifikationer er slået fra';
export const SUCCESS_TOAST_MESSAGES_UNMUTED = 'Notifikationer er slået til';
export const SUCCESS_TOAST_MESSAGES_DELETE = 'Beskedtråden blev slettet';
export const SUCCESS_TOAST_MESSAGES_DELETE_MULTI = 'Beskederne blev slettet';
export const SUCCESS_TOAST_MESSAGES_CREATE = 'Beskeden blev sendt';
export const SUCCESS_TOAST_MESSAGES_MARK_AS_SENSITIVE = 'Beskeden er markeret som følsom';
export const SUCCESS_TOAST_MESSAGES_UNMARK_AS_SENSITIVE = 'Beskeden er ikke længere markeret som følsom';
export const SUCCESS_TOAST_MESSAGES_MOVE_THREAD = 'Beskeden er flyttet';
export const SUCCESS_TOAST_MESSAGES_MOVE_MULTI_THREADS = 'Beskederne er flyttet';
export const SUCCESS_TOAST_MESSAGES_FOLDER_CREATE = 'Mappen blev oprettet';
export const SUCCESS_TOAST_MESSAGES_FOLDER_DELETE = 'Mappen blev slettet';
export const SUCCESS_TOAST_MESSAGES_FOLDER_RENAMED = 'Navnet på mappen blev ændret.';
export const SUCCESS_TOAST_POST_CREATE = 'Opslaget blev oprettet';
export const SUCCESS_TOAST_POST_EDIT = 'Opslaget blev opdateret';
export const SUCCESS_TOAST_POST_DELETE = 'Opslaget blev slettet';
export const SUCCESS_TOAST_ALBUM_CREATE = 'Album blev oprettet';
export const SUCCESS_TOAST_ALBUM_UPDATE = 'Album blev opdateret';
export const SUCCESS_TOAST_MEDIA_CREATE = 'Medier blev oprettet';
export const SUCCESS_TOAST_MEDIA_UPDATE = 'Medier blev opdateret';
export const SUCCESS_TOAST_SINGLE_MEDIA_DELETE = 'Mediet blev slettet';
export const SUCCESS_TOAST_PLURAL_MEDIA_DELETE = 'Medierne blev slettet';
export const SUCCESS_TOAST_UPDATED_CONSENT_AGE = 'Ændringerne er gemt';
export const SUCCESS_TOAST_REPORTED_COMMENT = 'Det anmeldte indhold er fjernet';
export const SUCCESS_TOAST_DELETED_REPEATING_PRESENCE_TEMPLATE = 'En gentagelse blev slettet';
export const SUCCESS_TOAST_ALBUM_DELETE = 'Albummet blev slettet';
export const SUCCESS_TOAST_ACCESS_CONFIGURATION_SAVED = 'Dine ændringer er gemt';
export const SUCCESS_TOAST_UPDATE_DATA_POLICY = 'Privatlivspolitikken blev opdateret';
export const SUCCESS_TOAST_UPDATED_DATA_EXPORT_CONFIGURATION = 'Dataudtræk konfiguration blev opdateret.';
export const SUCCESS_TOAST_PRESENCE_HOLIDAY_DELETE = 'Ferie/fri blev slettet';
export const SUCCESS_TOAST_PRESENCE_HOLIDAY_UPDATE = 'Ferie/fri blev opdateret';
export const SUCCESS_TOAST_SURVEY_CREATED = 'Brugerundersøgelse blev oprettet';
export const SUCCESS_TOAST_SURVEY_UPDATED = 'Brugerundersøgelse blev opdateret';
export const SUCCESS_TOAST_SURVEY_DELETED = 'Surveyen blev slettet';
export const SUCCESS_TOAST_GENERAL_INFORMATION_CREATED = 'Informationsudsendelse blev oprettet';
export const SUCCESS_TOAST_GENERAL_INFORMATION_UPDATED = 'Informationsudsendelse blev opdateret';
export const SUCCESS_TOAST_GENERAL_INFORMATION_DELETED = 'Informationsudsendelse er blevet slettet';
export const SUCCESS_TOAST_WIDGET_PERMISSION_DELETED = 'Widget blev fjernet';
export const SUCCESS_TOAST_WIDGET_PERMISSION_ADDED = 'Widget blev tilføjet';
export const SUCCESS_TOAST_WIDGET_PERMISSION_UPDATED = 'Widget blev redigeret';
export const SUCCESS_TOAST_EDITOR_PLUGINS_UPDATED = 'Dine ændringer er gemt.';
// WARNINGS
export const WARNING_OPEN_EXTERNAL_LINK_1 = 'Du har klikket på et eksternt link og er ved at forlade Aula.';
export const WARNING_OPEN_EXTERNAL_LINK_2 = 'Er du sikker på, at du vil fortsætte?';
export const WARNING_UNILOGIN_MISMATCH =
  'Du kunne ikke logges ind på et højere sikkerhedsniveau. Dette kan skyldes følgende.';
export const WARNING_UNILOGIN_MISMATCH_REASON_1 =
  'Du forsøger at logge ind med et NemID eller en bruger som ikke matcher dit login i Aula.';
export const WARNING_UNILOGIN_MISMATCH_REASON_2 =
  'Du har valgt at logge ind som forælder eller kontaktperson i Unilogin, men var allerede logget ind som medarbejder.';
export const WARNING_UNILOGIN_MISMATCH_REASON_3 =
  'Du anvender en kommunal IDP, der ikke tilbyder et tilstrækkeligt højt sikkerhedsniveau.';
export const WARNING_UNILOGIN_MISMATCH_2 = 'Vil du prøve at logge ind igen?';
export const WANRING_VACATION_REQUEST_ANSWERED =
  'Denne ferieanmodning er allerede besvaret og kan ikke besvares igen. Hvis du har brug for at oprette yderligere ferie/fri eller ændre komme/gå tider kan du gøre dette fra komme/gå modulet';

// ERRORS
export const ERROR_MISSING_THIRD_PARTY_COOKIES =
  'Cookies er slået fra i din browser. Du skal slå cookies til for at benytte Google Drive i Aula.';

// UPLOAD
export const UPLOAD_BUTTON_LABEL = 'Vedhæft fil';
export const UPLOAD_MODAL_ERROR_MAX_SIZE_TITLE = 'ADVARSEL';
export const UPLOAD_MODAL_ERROR_EXT_BUTTON_CLOSE = 'OK';
export const UPLOAD_MODAL_ERROR_EXT_TITLE = 'ADVARSEL';
export const UPLOAD_MODAL_ERROR_MAX_WIDTH_HEIGHT_BODY =
  'Billeder større end 1600x1600 px nedskaleres til denne størrelse';
export const UPLOAD_MODAL_ERROR_MAX_DURATION_BODY =
  'En eller flere af de filer du uploader overskrider længde på 20 minutter og vil blive fjernet';
export const UPLOAD_MODAL_ERROR_MAX_SIZE_BUTTON_CLOSE = 'OK';
export const UPLOAD_MODAL_ERROR_ONLY_UPLOAD_EXT_TYPE1 = 'Du kan kun uploade ';
export const UPLOAD_MODAL_ERROR_ONLY_UPLOAD_EXT_TYPE2 = '-filer';
export const GALLERY_DELETE_MEDIA_CONFIRM = 'Er du sikker på, at du vil slette mediet?';
export const GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_SINGULAR_1 = 'Du er ved at slette {numberOfMedia} medie.';
export const GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_SINGULAR_2 = 'Hvis du fortsætter, slettes mediet for alle.';
export const GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_PLURAL_1 = 'Du er ved at slette {numberOfMedia} medier.';
export const GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_PLURAL_2 = 'Hvis du fortsætter, slettes medierne for alle.';
export const GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_3 = 'Er du sikker på, at du vil fortsætte?';
export const GALLERY_GO_TO_ALBUM = 'Gå direkte til mediets album';
export const GALLERY_DELETE_MEDIA_FROM_SEARCH = 'Der kan gå op til et minut før mediet bliver slettet fra søgning';
export const UPLOAD_MODAL_ERROR_TITLE_IS_TOO_LONG = 'Navnet på filen er for langt. Det må max være 50 tegn';
export const UPLOAD_MODAL_ERROR_MULTIPLE_DOT = 'Filnavnet må ikke indeholde punktummer';
export const UPLOAD_MODAL_ERROR_TOO_MANY_MEDIA_FILES = 'Album må ikke indeholde mere end 1.000 medier';
export const GALLERY_DOWLOAD_ALBUM_WARNING_1 = 'Du er ved at downloade alle medier i albummet.';
export const GALLERY_DOWLOAD_ALBUM_WARNING_2 = 'Er du sikker på, at du vil fortsætte?';

// Upload
export const UPLOAD_MODAL_ERROR_LOOSE_ALL_CHANGES =
  'Hvis du forlader siden for at logge ind på et højere sikkerhedsniveau mister du alle ugemte ændringer. Er du sikker på, at du vil fortsætte?';
export const UPLOAD_MODAL_ERROR_MAX_VIDEO_SIZE =
  'En eller flere af de filer du uploader overskrider størrelse på {size} og vil blive fjernet';
export const UPLOAD_MODAL_ERROR_MAX_SIZE_BODY =
  'Du kan ikke vedhæfte denne fil, da størrelsen overstiger den maksimale filstørrelse i Aula';
export const UPLOAD_MODAL_ERROR_EXIST_BODY =
  'Du kan ikke vedhæfte denne fil, da en fil af samme navn allerede eksisterer';
export const UPLOAD_MODAL_ERROR_NOT_MEDIA =
  'Filen har ikke et format, der er godkendt. Du kan vedhæfte følgende filtyper:';
export const UPLOAD_MODAL_ERROR_NOT_MEDIA_PROFILE_PICTURE =
  'Filen har ikke et format, der er godkendt. Du kan kun uploade billeder';
export const UPLOAD_MODAL_ERROR_FILES = 'Filer det vedrører:';
export const UPLOAD_MODAL_ERROR_BUTTON_CLOSE = 'OK';
export const UPLOAD_ADD_GROUPS_PLACEHOLDER = 'Søg blandt grupper';
export const UPLOAD_MODAL_ERROR_EXT_BODY =
  'Du kan ikke vedhæfte denne fil, da formatet ikke er godkendt. Formater som er tilladt:';
export const UPLOAD_MODAL_ERROR_TITLE = 'ADVARSEL';
export const UPLOAD_MODAL_MEDIA_NOT_ALLOWED =
  'Du har ikke tilladelse til at vedhæfte billeder, videoer eller lydfiler.';
export const DELETE_PROFILE_PICTURE_WARNING_1 =
  'Du er ved at slette et profilbillede. Hvis der er indlæst et foto i Aula fra de administrative systemer, benyttes dette fremover. Ellers vises initialer.';
export const DELETE_PROFILE_PICTURE_WARNING_2 = 'Vil du fortsætte?';
export const UPDATE_PROFILE_PICTURE_TOASTR = 'Billedet er gemt';
export const ATTACHMENT_MEDIA_STATUS_PENDING = 'Samtykker beregnes';
export const ATTACHMENT_FILE_STATUS_PENDING = 'Vedhæftet fil behandles';
export const ATTACHMENT_UPDATE_WARNING_NOT_EXIST =
  'Kan ikke opdatere mediet, da det ikke eksisterer eller er blevet slettet';
export const ATTACHMENT_FILE_NAME_UNACCESSABLE = 'Du har ikke adgang til denne sikre fil';
export const ATTACHMENT_FILE_NAME_NOT_STEPPED_UP = 'Sikker fil';
export const ATTACHMENT_HAS_BEEN_DELETED = 'Denne sikre fil er blevet slettet';
export const UPLOAD_AVATAR_UNDER_INSTITUTION = 'Billedet vises kun på';

// FILEPICKER
export const FILEPICKER_SECURE_FILESHARING = 'Sikre filer';
export const FILEPICKER_SECURE_MY_COMPUTER = 'Min enhed';
export const FILEPICKER_PROFILE_PICTURE = 'Profilbillede';
export const FILEPICKER_APPLY_PICTURE_ON_ALL_PROFILES = 'Brug profilbilledet på alle dine profiler';
export const FILEPICKER_CHOOSE_PROFILE_PICTURE = 'Vælg profilbillede';
export const FILEPICKER_CHANGE_PROFILE_PICTURE = 'Skift profilbillede';
export const FILEPICKER_UPLOAD_PROFILE_PICTURE = 'Gem profilbillede';
export const FILEPICKER_DELETE_PROFILE_PICTURE = 'Slet profilbillede';
export const FILEPICKER_GALLERY = 'Galleri';
export const FILEPICKER_UPLOAD_GOOGLE_DOCS_WARNING = 'Du kan ikke vedhæfte følgende filer da det er Google Docs filer:';
export const SHARED_UPLOAD_FILE = 'Tilføj fil';

// STEPUP
export const STEPUP_MODAL_TITLE = 'Log på Administrationsmodulet';
export const STEPUP_MODAL_TITLE_MESSAGES = 'To-faktor login';
export const STEPUP_MODAL_MESSAGE = 'Du skal logge ind for at få adgang til Administrationsmodulet';
export const STEPUP_MODAL_MESSAGE_MESSAGES =
  'For at anvende denne funktionalitet, bedes du autentificere dig yderligere';
export const STEPUP_MODAL_BUTTON_CANCEL = 'Annuller';
export const STEPUP_MODAL_BUTTON_STEPUP = 'Log ind';
export const STEPUP_MODAL_WARNING_OK_BUTTON = 'OK';
export const STEPUP_MODAL_ERROR_MESSAGE = 'Der gik noget galt. Prøv igen';

// WIDGET
export const WIDGET_SUPPLIED_BY = 'Leveret af';

// Reported Content
export const REPORTED_CONTENT_REPORT = 'Anmeld';
export const REPORTED_CONTENT_HEADER = 'Anmeld indhold';
export const REPORTED_CONTENT_SUCCESS_TOASTR = 'Indholdet er anmeldt';
export const REPORTED_CONTENT_WRITE_A_REASON = 'Skriv en begrundelse for anmeldelsen:';
export const REPORTED_CONTENT_CANCEL_REPORT_1 = 'Din anmeldelse vil ikke blive gemt.';
export const REPORTED_CONTENT_CANCEL_REPORT_2 = 'Er du sikker på, du vil fortsætte?';
export const REPORTED_CONTENT_NOTE =
  'Bemærk, indholdet bliver midlertidigt skjult og anmeldelsen bliver sendt til en administrator som tager stilling til din anmeldelse.';
export const REPORTED_CONTENT_SEND = 'Send';
export const REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_SHORT = 'Du skal skrive en begrundelse på minimum 10 tegn.';
export const REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_LONG = 'Du skal skrive en begrundelse på højst 225 tegn.';
export const REPORTED_CONTENT_FIELDS_REPORTER = 'Anmelder';
export const REPORTED_CONTENT_FIELDS_DATE = 'Dato';
export const REPORTED_CONTENT_FIELDS_LINK = 'Link';
export const REPORTED_CONTENT_FIELDS_REASON = 'Begrundelse';
export const REPORTED_CONTENT_FIELDS_AUTHOR = 'Delt Af';
export const REPORTED_CONTENT_FIELDS_HANDLE_REPORT = 'Håndter anmeldelse';
export const REPORTED_CONTENT_FIELDS_ACCEPT = 'Slet indhold';
export const REPORTED_CONTENT_FIELDS_REJECT = 'Afvis anmeldelse';
export const REPORTED_CONTENT_TITLE = 'Anmeldt indhold';
export const REPORTED_CONTENT_TITLE_GROUP = 'Anmeldelser:';
export const REPORTED_CONTENT_TITLE_POSTS = 'Opslag';
export const REPORTED_CONTENT_TITLE_MEDIA = 'Medier';
export const REPORTED_CONTENT_TITLE_COMMENTS = 'Kommentarer';
export const REPORTED_CONTENT_DESCRIPTION =
  'Her kan du se en oversigt over hvilket indhold, der er blevet anmeldt. Du kan se, hvem der har anmeldt indholdet, hvorfor de har anmeldt det og link til at se det anmeldte indhold. Du kan også se hvem, der er afsender af det delte indhold. Derudover kan du vælge, hvordan du vil håndtere det anmeldte indhold.';
export const REPORTED_CONTENT_POST_BOX_TITLE = 'ANMELDT INDHOLD';
export const REPORTED_CONTENT_LINK_LABEL = 'Se anmeldt indhold';
export const REPORTED_CONTENT_DELETE_CONFIRMATION = 'Er du sikker på, at du vil slette indholdet?';
export const MESSAGE_REPORTED_CONTENT_DELETED = 'Indholdet er blevet slettet';
export const MESSAGE_REPORTED_CONTENT_REJECTED = 'Anmeldelsen er blevet afvist og indholdet er synligt igen';
export const MESSAGE_REPORTED_CONTENT_EMPTY_TABLE = 'Der er ikke anmeldt noget indhold';

// - VIEW PROFILE -
export const PROFILE_PHONE_FORMAT_WARNING = 'Telefonnummeret er ikke udfyldt korrekt';
export const PROFILE_EMAIL_FORMAT_WARNING = 'Emailen er ikke udfyldt korrekt';
export const PROFILE_HOME_PHONE_LABEL = 'Hjemme';
export const PROFILE_MOBILE_PHONE_LABEL = 'Mobil';
export const PROFILE_WORK_PHONE_LABEL = 'Arbejds';
export const GROUP_HOME_PHONE_PRIMARY_LABEL = 'Primære tlf';
export const GROUP_HOME_PHONE_SECONDARY_LABEL = 'Sekundære tlf';
export const PROFILE_DESCRIPTION_LABEL_SHORT = 'Beskrivelse';
export const PROFILE_RELATION_LABEL_SHORT = 'Relation';
export const PROFILE_DESCRIPTION_LABEL = 'Angiv evt. en beskrivelse til din profilside';
export const PROFILE_DESCRIPTION_HELP_TEXT =
  'Du kan vælge at angive en beskrivelse, som andre kan se, når de søger dig frem.';
export const PROFILE_DESCRIPTION_ERROR_MAX_CONTENT = 'Du kan max skrive 600 karakterer';
export const PROFILE_EMAIL_LABEL = 'Email';
export const PROFILE_UPDATE_SUCCESS_TOASTR = 'Dine ændringer er gemt';
export const PROFILE_INFO_LABEL = 'Kontaktinfo';
export const PROFILE_ADDRESS_UNKOWN = 'Adresse ukendt';
export const PROFILE_PICTURE_DELETED = 'Billedet er slettet';
export const PROFILE_DEACTIVATED_DESCRIPTION = 'Denne bruger er ikke længere aktiv på institutionen.';
export const PROFILE_FORBIDDEN_DESCRIPTION = 'Denne bruger er fra en anden institution.';

// SEARCH COMPONENT
export const GROUP_LABEL_TYPE_CHILD = 'Børn';
export const GROUP_LABEL_TYPE_EMPLOYEE = 'Medarbejdere';
export const GROUP_LABEL_TYPE_GUARDIAN = 'Forældre';
export const GROUP_LABEL_TYPE_MEMBERS = 'Medlemmer';
export const GROUP_LABEL_TYPE_MEMBER = 'Medlem';
export const SEARCH_NO_MEMBERS_IN_GROUP =
  'Medlemmerne af gruppen er allerede tilføjet, eller der er ingen medlemmer i denne gruppe.';

// API ERRORS
export const API_ERROR_FIND_GENERIC = 'Der skete en fejl under søgningen';
export const API_ERROR_GET_COMMENTS = 'Kunne ikke hente kommentarer';
export const API_ERROR_CREATE_COMMENT = 'Kunne ikke oprette kommentar';
export const API_ERROR_UPDATE_COMMENT = 'Kunne ikke opdatere kommentar';
export const API_ERROR_DELETE_COMMENT = 'Kunne ikke slette kommentar';
export const API_ERROR_REPORT_COMMENT = 'Kunne ikke anmelde kommentar';
export const API_ERROR_GET_GROUPS_BY_CONTEXT = 'Kunne ikke hente grupper';
export const API_ERROR_GET_CONTACT_LIST = 'Kunne ikke hente kontakter';
export const API_ERROR_GET_CONTACT_PARENTS = 'Kunne ikke hente værger';
export const API_ERROR_CREATE_ALBUM = 'Kunne ikke oprette album';
export const API_ERROR_UPDATE_ALBUM = 'Kunne ikke opdatere album';
export const API_ERROR_CREATE_MEDIA = 'Kunne ikke oprette medier';
export const API_ERROR_UPDATE_MEDIA = 'Kunne ikke opdatere medier';
export const API_ERROR_DELETE_MEDIA = 'Kunne ikke slette medier';
export const API_ERROR_DELETE_ALBUM = 'Kunne ikke slette albummet';
export const API_ERROR_GET_ALBUMS = 'Kunne ikke hente albums';
export const API_ERROR_GET_MEDIA = 'Kunne ikke hente medier';
export const API_ERROR_REPORT_MEDIA = 'Kunne ikke anmelde medie';
export const API_ERROR_GET_WIDGETS_BY_ID = 'Kunne ikke hente widgets';
export const API_ERROR_GET_ADMIN_DASHBOARD_CONFIG = 'Kunne ikke hente dashboard konfiguration';
export const API_ERROR_GET_SAME_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS = 'Kunne ikke hente institutioner';
export const API_ERROR_SET_MEMBERSHIP = 'Kunne ikke opdatere medlemskab';
export const API_ERROR_GET_PERSONAL_DATA_POLICIES = 'Kunne ikke hente data politik';
export const API_ERROR_UPDATE_PROFILE_MASTER_DATA = 'Kunne ikke opdatere bruger data';
export const API_ERROR_GET_ADDITIONAL_DATA = 'Kunne ikke hente supplerende stamdata';
export const API_ERROR_GET_ANY_UNANSWERED_ADDITIONAL_DATA_RESPONSES =
  'Kunne ikke hente ubesvarede supplerende stamdata';
export const API_ERROR_UPDATE_ADDITIONAL_DATA = 'Kunne ikke opdatere supplerende stamdata';
export const API_ERROR_SAVE_ADDITIONAL_DATA = 'Kunne ikke gemme supplerende stamdata';
export const API_ERROR_DELETE_ADDITIONAL_DATA = 'Kunne ikke slette supplerende stamdata';
export const API_ERROR_MARK_ONBOARDING_COMPLETED = 'Kunne ikke markere onboarding til at være afsluttet';
export const API_ERROR_GET_PERSONAL_REFERENCE_DATA_QUESTION = 'Kunne ikke hente person oplysninger';
export const API_ERROR_CREATE_POST = 'Kunne ikke oprette opslag';
export const API_ERROR_UPDATE_POST = 'Kunne ikke opdatere opslag';
export const API_ERROR_GET_ALL_POSTS = 'Kunne ikke hente opslag';
export const API_ERROR_NOT_A_MEMBER = 'Du har ikke længere adgang til gruppen, da du ikke er medlem';
export const API_ERROR_GET_POST_BY_ID = 'Kunne ikke hente opslag';
export const API_ERROR_DELETE_POST = 'Kunne ikke slette opslag';
export const API_ERROR_REPORT_POST = 'Kunne ikke anmelde opslag';
export const API_ERROR_GET_PRESENCE_TEMPLATES = 'Kunne ikke hente komme/gå skabeloner';
export const API_ERROR_GET_PRESENCE_TEMPLATE_FOR_DATE = 'Kunne ikke hente komme/gå tider';
export const API_ERROR_GET_PRESENCE_CONFIGURATION = 'Kunne ikke hente komme/gå konfiguration';
export const API_ERROR_GET_PRESENCE_EDIT_OPTIONS = 'Kunne ikke hente redigeringsindstillinger';
export const API_ERROR_GET_PRESENCE_STATES = 'Kunne ikke hente status';
export const API_ERROR_UPDATE_PRESENCE_TEMPLATES = 'Kunne ikke opdatere komme/gå skabeloner';
export const API_ERROR_ADD_VACATION = 'Kunne ikke tilføje ferie/fri';
export const API_ERROR_GET_VACATION_REGISTRATIONS = 'Kunne ikke hente ferieanmodninger';
export const API_ERROR_GET_VACATION_ANNOUNCEMENTS = 'Kunne ikke hente ferie/fri';
export const API_ERROR_GET_VACATION_RESPONSE = 'Kunne ikke hente ferieanmodninger';
export const API_ERROR_GET_GENERAL_OPENING_HOURS = 'Kunne ikke hente generelle åbningstider';
export const API_ERROR_GET_SPECIFIC_OPENING_HOURS = 'Kunne ikke hente særlige åbningstider';
export const API_ERROR_GET_HOLIDAY_CLOSED_DAYS = 'Kunne ikke hente lukkedage';
export const API_ERROR_SET_CO_ORGANIZER_AS_INVITEE = 'Du kan ikke vælge en medarrangør, der også er deltager';
export const API_ERROR_UPDATE_PRESENCE_STATUS = 'Kunne ikke opdatere komme/gå status';
export const API_ERROR_GET_PICKUP_RESPONSIBLES = 'Kunne ikke hente komme/gå henteansvarlige';
export const API_ERROR_GET_GO_HOME_WITH = 'Kunne ikke hente komme/gå gå-hjem-med listen';
export const API_ERROR_SAVE_PICKUP_RESPONSIBLE = 'Kunne ikke gemme komme/gå henteansvarlig';
export const API_ERROR_DELETE_PICKUP_RESPONSIBLE = 'Kunne ikke slette komme/gå henteansvarlig';
export const API_ERROR_GET_ACTIVITY_FILTER = 'Kunne ikke hente komme/gå filtre';
export const API_ERROR_GET_ACTIVITY_LIST = 'Kunne ikke hente komme/gå aktivitetsliste';
export const API_ERROR_GET_WEEK_OVERVIEW = 'Kunne ikke hente komme/gå ugeoverblik';
export const API_ERROR_GET_PRESENCE_DISTRIBUTION = 'Kunne ikke hente udviklingen af antal børn til stede';
export const API_ERROR_GET_MUNICIPAL_IDPS = 'Kunne ikke hente kommune IDP';
export const API_ERROR_RESPOND_TO_EVENT = 'Kunne ikke gemme dit svar';
export const API_ERROR_RESPOND_TO_EVENT_DEADLINE = 'Deadline for svar er overskredet';
export const API_ERROR_GET_EVENT = 'Begivenheden er ikke længere tilgængelig';
export const API_ERROR_GET_EVENTS = 'Kunne ikke hente begivenheder';
export const API_ERROR_CHECK_CONFLICTING_EVENTS = 'Kunne ikke tjekke begivenheder for de inviterede';
export const API_ERROR_DELETE_EVENT = 'Kunne ikke slette begivenhed';
export const API_ERROR_GET_EVENT_TYPES = 'Kunne ikke hente begivenhedstyper';
export const API_ERROR_UPDATE_EVENT = 'Kunne ikke opdatere begivenhed';
export const API_ERROR_SET_DELEGATED_ACCESS = 'Kunne ikke give råderet over kalenderen';
export const API_ERROR_GET_DELEGATED_ACCESS = 'Kunne ikke hente personer du har givet råderet til';
export const API_ERROR_GET_IMPORTANT_DATES = 'Kunne ikke hente vigtige begivenheder';
export const API_ERROR_BLOCK_TIMESLOT = 'Kunne ikke blokere tidspunktet';
export const API_ERROR_REMOVE_BLOCK_TIMESLOT = 'Kunne ikke slette svaret';
export const API_ERROR_GET_DELEGATED_CONTEXT = 'Kunne ikke hente kalenderen (råderet)';
export const API_ERROR_SET_DELEGATED_CONTEXT = 'Kunne ikke sætte kalenderen (råderet)';
export const API_ERROR_GET_PROFILES_WITH_DELEGATED_ACCESS = 'Kunne ikke hente kalendere du har råderet over';
export const API_ERROR_LIST_AVAILABLE_RESOURCES = 'Kunne ikke hente tilgængelige ressourcer';
export const API_ERROR_LIST_RESOURCES = 'Kunne ikke hente ressourcer';
export const API_ERROR_UPDATE_EVENT_RESOURCES = 'Kunne ikke opdatere ressourcer på begivenheden';
export const API_ERROR_REQUEST_VACATION = 'Kunne ikke oprette ferieanmodning';
export const API_ERROR_LOAD_VACATION_REQUESTS = 'Kunne ikke hente ferieanmodninger';
export const API_ERROR_GET_CONSENT_REPSONSES = 'Kunne ikke hente samtykker';
export const API_ERROR_UPDATE_CONSENT_REPSONSES = 'Kunne ikke opdatere samtykker';
export const API_ERROR_UPDATE_LOCKED_STATUS = 'Kunne ikke låse fil/låse fil op';
export const API_ERROR_GET_SECURE_DOCUMENTS = 'Kunne ikke hente filer';
export const API_ERROR_GET_DELETED_SECURE_DOCUMENTS = 'Kunne ikke slettede filer';
export const API_ERROR_CREATE_SECURE_DOCUMENTS = 'Kunne ikke oprette fil';
export const API_ERROR_UPDATE_SECURE_DOCUMENTS = 'Kunne ikke opdatere fil';
export const API_ERROR_GET_DOCUMENT_REVISIONS = 'Kunne ikke hente fil revision';
export const API_ERROR_GET_DOCUMENT_CATEGORIES_AND_TEMPLATES = 'Kunne ikke hente kategorier og skabelon';
export const API_ERROR_UPDATE_SHARINGS = 'Kunne ikke opdatere hvem filen er delt med';
export const API_ERROR_GET_SECURE_DOCUMENT = 'Kunne ikke hente fil';
export const API_ERROR_SET_MAX_FILE_SIZE = 'Kunne ikke gemme værdi for max filstørrelse';
export const API_ERROR_GET_MAX_FILE_SIZE = 'Kunne ikke hente værdi for max filstørrelse';
export const API_ERROR_GET_AUTHORIZED_FILE_FORMATS = 'Kunne ikke hente gyldige filformater';
export const API_ERROR_REMOVE_AUTHORIZED_FILE_FORMAT = 'Kunne ikke slette filformat';
export const API_ERROR_CREATE_AUTHORIZED_FILE_FORMAT = 'Kunne ikke oprette filformat';
export const API_ERROR_UPDATE_PROFILE_PICTURE = 'Kunne ikke opdatere profilbillede';
export const API_ERROR_CREATE_ATTACHMENTS =
  'Kunne ikke vedhæfte en eller flere af filerne. Tjek at filerne stadig er tilgængelige på dit drev.';
export const API_ERROR_CANNOT_TAG_OTHER_INSTITUTION = 'Det er ikke muligt at tagge personer fra andre institutioner';
export const API_ERROR_CANNOT_TAG_OTHER_MUNICIPAL = 'Det er ikke muligt at tagge en person fra en anden kommune';
export const API_ERROR_CANNOT_SHARE_DOCUMENT_TO_OTHER_MUNICIPAL =
  'Du har ikke tilladelse til at dele filen med de valgte brugere';
export const API_ERROR_GET_PROFILE_CONTEXT = 'Kunne ikke hente din profil';
export const API_ERROR_KEEP_SESSION_ALIVE = 'Kunne ikke holde sessionen i live';
export const API_ERROR_GET_BLOCKED_COMMUNICATION_PROFILES = 'Kunne ikke hente blokerede kommunikationsprofiler';
export const API_ERROR_COMMUNICATION_CHANNELS_BLOCKED =
  'Handlingen kunne ikke udføres pga. blokerede kommunikationskanaler';
export const API_ERROR_IMPERSONATE_USER = 'Kunne ikke impersonere brugeren';
export const API_ERROR_GET_GROUP_BY_ID = 'Kunne ikke hente gruppen';
export const API_ERROR_GET_MEMBERSHIPS = 'Kunne ikke hente medlemskaber';
export const API_ERROR_CREATE_GROUP = 'Kunne ikke oprette gruppe';
export const API_ERROR_UPDATE_GROUP = 'Kunne ikke opdatere gruppe';
export const API_ERROR_DELETE_GROUP = 'Kunne ikke slette gruppe';
export const API_ERROR_GET_ALL_MODULES = 'Kunne ikke hente moduler';
export const API_ERROR_GET_ALL_WIDGETS = 'Kunne ikke hente widgets';
export const API_ERROR_GET_PERMITTED_WIDGETS = 'Kunne ikke laste ned tillatte widgets';
export const API_ERROR_LIST_ROLE_DEFINITIONS = 'Kunne ikke hente rolle definitioner';
export const API_ERROR_SET_ROLES = 'Kunne ikke gemme roller';
export const API_ERROR_SET_ADMIN_DEACTIVATION_FOR_GROUPS = 'Kunne ikke deaktivere grupper';
export const API_ERROR_GET_GROUPS_IN_USE = 'Kunne ikke få grupper i brug';
export const API_ERROR_GET_MESSAGES_FOR_THREAD = 'Kunne ikke hente beskeder';
export const API_ERROR_LOAD_MESSAGE_REVISIONS = 'Kunne ikke hente beskeder';
export const API_ERROR_START_NEW_THREAD = 'Kunne ikke oprette besked';
export const API_ERROR_DELETE_THREADS = 'Kunne ikke slette beskeder';
export const API_ERROR_DELETE_MESSAGE = 'Kunne ikke slette beskeden';
export const API_ERROR_SET_THREADS_MUTED = 'Kunne ikke mute/unmute beskeden';
export const API_ERROR_SET_THREADS_MARKED = 'Kunne ikke markere beskeden';
export const API_ERROR_LEAVE_THREAD = 'Kunne ikke forlade beskeden';
export const API_ERROR_LEAVE_THREADS = 'Kunne ikke efterlade beskeder';
export const API_ERROR_UPDATE_SUBSCRIPTION = 'Kunne ikke opdatere beskeden';
export const API_ERROR_UPDATE_SUBSCRIPTIONS = 'Kunne ikke opdatere beskederne';
export const API_ERROR_GET_AUTO_REPLY = 'Kunne ikke hente autosvar';
export const API_ERROR_GET_FOLDERS = 'Kunne ikke hente mapper';
export const API_ERROR_DELETE_FOLDER = 'Kunne ikke slette mappe';
export const API_ERROR_CHANGE_FOLDER_NAME = 'Kunne ikke omdøbe mappe';
export const API_ERROR_CREATE_FOLDER = 'Kunne ikke oprette mappe';
export const API_ERROR_CREATE_AUTO_REPLY = 'Kunne ikke oprette autosvar';
export const API_ERROR_DELETE_AUTO_REPLY = 'Kunne ikke slette autosvar';
export const API_ERROR_MOVE_THREADS_TO_FOLDER = 'Kunne ikke beskeder til mappe';
export const API_ERROR_FIND_MESSAGE = 'Kunne ikke søge i beskeder';
export const API_ERROR_ADD_RECIPIENTS = 'Kunne ikke tilføje modtagere til beskeden';
export const API_ERROR_GET_COMMON_INBOXES = 'Kunne ikke hente fællespostkasser';
export const API_ERROR_CREATE_UPDATE_COMMON_INBOX = 'Kunne ikke oprette/opdatere fællespostkasse';
export const API_ERROR_DELETE_COMMON_INBOX = 'Kunne ikke slette fællespostkasse';
export const API_ERROR_SET_SENSITIVITY_LEVEL = 'Kunne ikke opdatere følsomhedsniveau';
export const API_ERROR_ATTACH_MESSAGES_TO_DOCUMENT = 'Kunne ikke vedhæfte beskeder til fil';
export const API_ERROR_GET_NOTIFICATION_SETTINGS = 'Kunne ikke hente notifikationsindstillinger';
export const API_ERROR_GET_NOTIFICATIONS = 'Kunne ikke hente notifikationer';
export const API_ERROR_UNREGISTER_DEVICE = 'Kunne ikke fjerne enhed';
export const API_ERROR_DELETE_NOTIFICATIONS = 'Kunne ikke slette notifikationer';
export const API_ERROR_DELETE_MODULE_NOTIFICATIONS = 'Kunne ikke slette notifikationer';
export const API_ERROR_GET_REPORTED_CONTENT = 'Kunne ikke hente anmeldelser';
export const API_ERROR_ACCEPT_REPORTED_CONTENT = 'Kunne ikke godkende anmeldelse';
export const API_ERROR_REJECT_REPORTED_CONTENT = 'Kunne ikke afvise anmeldelse';
export const API_ERROR_GET_PROFILE_MASTER_DATA = 'Kunne ikke hente bruger data';
export const API_ERROR_SET_DENY_ROLES = 'Kunne ikke opdatere blokeret kommunikation for bruger';
export const API_ERROR_DELETE_USER_DATA = 'Kunne ikke slette bruger data';
export const API_ERROR_HANDLE_ENTITIES_ADMIN = 'Kunne ikke oprette sletning/eksport';
export const API_ERROR_GET_RIGHT_TO_BE_FORGOTTEN_REQUESTS = 'Kunne ikke hente sletninger/eksporteringer';
export const API_ERROR_GET_INSTITUTION_AND_ADMINISTRATIVE_AUTHORITIES = 'Kunne ikke hente forvaltningsmyndigheder';
export const API_ERROR_GET_INSTITUTION_BY_ADMINISTRATIVE_AUTHORITY = 'Kunne ikke hente institutioner';
export const API_ERROR_SAVE_ADMINISTRATIVE_AUTHORITY = 'Kunne ikke gemme forvaltningsmyndighed';
export const API_ERROR_GET_COMMON_FILES = 'Kunne ikke hente fælles filer';
export const API_ERROR_DELETE_COMMON_FILE = 'Kunne ikke slette fælles fil';
export const API_ERROR_UPDATE_COMMON_FILE = 'Kunne ikke opdatere fælles fil';
export const API_ERROR_CREATE_COMMON_FILE = 'Kunne ikke oprette fælles fil';
export const API_ERROR_TITLE_EXIST_ON_INSTITUTION = 'Kunne ikke tjekke om titel allerede eksisterer';
export const API_ERROR_GET_COMMON_FILE = 'Kunne ikke hente fælles fil';
export const API_ERROR_GET_INSTITUTION_POLICY_NAME = 'Kunne ikke hente institutionsnavn';
export const API_ERROR_GET_CENTRAL_COMMUNICATION_CHANNELS = 'Kunne ikke hente kommunikationskanaler';
export const API_ERROR_CREATE_CENTRAL_COMMUNICATION_CHANNEL = 'Kunne ikke oprette kommunikationskanal';
export const API_ERROR_REMOVE_CENTRAL_COMMUNICATION_CHANNEL = 'Kunne ikke slette kommunikationskanal';
export const API_ERROR_LOAD_CONSENT_LIST = 'Kunne ikke hente samtykker';
export const API_ERROR_UPDATE_CONSENT = 'Kunne ikke opdatere samtykker';
export const API_ERROR_GET_DASHBOARD_CONFIGURATION = 'Kunne ikke hente dashboard konfiguration';
export const API_ERROR_UPDATE_DASHBOARD_CONFIGURATION = 'Kunne ikke opdatere dashboard konfiguration';
export const API_ERROR_GET_PRESENCE_LOCATIONS = 'Kunne ikke hente komme/gå lokationer';
export const API_ERROR_SAVE_PRESENCE_LOCATION = 'Kunne ikke gemme komme/gå lokation';
export const API_ERROR_REMOVE_PRESENCE_LOCATION = 'Kunne ikke slette komme/gå lokation';
export const API_ERROR_SET_PRESENCE_CONFIGURATION = 'Kunne ikke opdatere komme/gå konfiguration';
export const API_ERROR_GET_STATISTICS = 'Kunne ikke opdatere komme/gå statistik';
export const API_ERROR_GENERATE_PRESENCE_TOKEN = 'Kunne ikke generere komme/gå token';
export const API_ERROR_GET_RESOURCE_BY_ID = 'Kunne ikke hente ressource';
export const API_ERROR_CREATE_UPDATE_RESOURCE_CATEGORY = 'Kunne ikke oprette/opdatere ressource kategori';
export const API_ERROR_CREATE_UPDATE_RESOURCE = 'Kunne ikke oprette/opdatere ressource';
export const API_ERROR_GET_RESOURCE_CATEGORIES = 'Kunne ikke hente ressource kategorier';
export const API_ERROR_DELETE_RESOURCE_CATEGORY = 'Kunne ikke slette ressource kategori';
export const API_ERROR_DELETE_RESOURCE = 'Kunne ikke slette ressource';
export const API_ERROR_GET_SERVICE_MESSAGE = 'Kunne ikke hente service besked';
export const API_ERROR_SET_SERVICE_MESSAGE = 'Kunne ikke gemme service besked';
export const API_ERROR_GET_SERVICE_CATALOG = 'Kunne ikke hente service katalog';
export const API_ERROR_GET_SERVICE_CASES = 'Kunne ikke hente service sager';
export const API_ERROR_GET_SERVICE_DESCRIPTIONS = 'Kunne ikke hente service beskrivelser';
export const API_ERROR_ADD_SERVICE_CASE_ATTACHMENTS = 'Kunne ikke gemme service vedhæftninger';
export const API_ERROR_GET_SERVICE_CASE_ATTACHMENTS = 'Kunne ikke hente service vedhæftninger';
export const API_ERROR_IS_EMAIL_IN_USE = 'Kunne ikke tjekke om email allerede er brugt';
export const API_ERROR_IS_COMMON_INBOX_NAME_IN_USE = 'Kunne ikke tjekke om fællespostkasse navn allerede er brugt';
export const API_ERROR_GET_SEARCH_INDICES = 'Kunne ikke hente søge indeks';
export const API_ERROR_FIND_IN_INDEX = 'Kunne ikke søge i indekset';
export const API_ERROR_GET_USER_DESCRIPTION = 'Kunne ikke hente bruger beskrivelse';
export const API_ERROR_LOOKUP_INSTITUTION_UNI_LOGIN = 'Kunne ikke hente UNI-login';
export const API_ERROR_GET_MUNICIPAL_CONFIGURATION = 'Kunne ikke hente kommunale konfigurationer';
export const API_ERROR_GET_INSTITUIONAL_CONFIGURATION = 'Kunne ikke hente institutionelle konfigurationer';
export const API_ERROR_SET_TRANSFER_YEAR = 'Kunne ikke gemme overgangsår';
export const API_ERROR_SET_CONSENT_AGE = 'Kunne ikke gemme samtykke år';
export const API_ERROR_DELETE_INSTITUTIONAL_CONFIGURATION = 'Kunne ikke slette institutionel konfiguration';
export const API_ERROR_CREATE_INSTITUTIONAL_CONFIGURATION = 'Kunne ikke oprette institutionel konfiguration';
export const API_ERROR_EDIT_INSTITUTIONAL_CONFIGURATION = 'Kunne ikke opdatere institutionel konfiguration';
export const API_ERROR_SEND_SERVICE_REQUEST = 'Kunne ikke oprette service sag';
export const API_ERROR_SEND_APPLICATION_HELP_REQUEST = 'Kunne ikke oprette anmodning';
export const API_ERROR_GET_FILTER = 'Kunne ikke hente filter';
export const API_ERROR_GET_GROUP_TEMPLATES = 'Kunne ikke hente gruppeskabeloner';
export const API_ERROR_GET_GROUP_TEMPLATE_BY_ID = 'Kunne ikke hente gruppeskabelon';
export const API_ERROR_DELETE_GROUP_TEMPLATE = 'Kunne ikke slette gruppeskabelonen';
export const API_ERROR_GET_MATCHING_GROUPS_FOR_GROUP_TEMPLATE = 'Kunne ikke få matchende grupper til gruppeskabelon';
export const API_ERROR_APPLY_MATCH_GROUPS_FOR_GROUP_TEMPLATE =
  'Kunne ikke anvende matchende grupper til gruppeskabelon';
export const API_ERROR_LOAD_EXTERNAL_SYSTEM = 'Kunne ikke indlæse eksterne systemer.';
export const API_ERROR_LOAD_DATA_EXPORT_CONFIGURATION = 'Kunne ikke indlæse dataudtræk konfiguration.';
export const API_ERROR_SAVE_ACCESS_CONFIGURATION = 'Kunne ikke gemme adgangskontrol.';
export const API_ERROR_DELETE_REPEATING_PRESENCE_TEMPLATE = 'Kunne ikke slette en gentagelse';
export const API_ERROR_CREATE_GROUP_TEMPLATE = 'Kunne ikke oprette gruppeskabelon';
export const API_ERROR_UPDATE_GROUP_TEMPLATE = 'Kunne ikke opdatere gruppeskabelon';
export const API_ERROR_LOAD_DATA_POLICY = 'Kunne ikke hente datapolitik';
export const API_ERROR_UPDATE_DATA_POLICY = 'Kunne ikke opdatere datapolitik';
export const API_ERROR_UPDATE_CALENDAR_SYNC_CONSENT_ENABLED = 'Kunne ikke opdatere status for kalender synkronisering';
export const API_ERROR_LOAD_CALENDAR_SYNC_CONSENT_ENABLED = 'Kunne ikke hente status for kalender synkronisering';
export const API_ERROR_LOAD_CALENDAR_FEEDS = 'Kunne ikke hente kalender feeds';
export const API_ERROR_UPDATE_CALENDAR_FEED = 'Kunne ikke opdatere kalender feedet';
export const API_ERROR_DELETE_CALENDAR_FEED = 'Kunne ikke slette kalender feedet';
export const API_ERROR_CREATE_CALENDAR_FEED = 'Kunne ikke oprette kalender feedet';
export const API_ERROR_UPDATE_CALENDAR_FEED_ENABLED = 'Kunne ikke opdatere status for kalender synkronisering';
export const API_ERROR_LOAD_CALENDAR_FEED_ENABLED = 'Kunne ikke hente status for kalender synkronisering';
export const API_ERROR_LOAD_CALENDAR_FEED_EVENTS = 'Kunne ikke hente event typer for kalender synkronisering';
export const API_ERROR_EXPORT_PDF = 'Der opstod en fejl under eksporteringen af sikre filer. Prøv igen senere.';
export const API_ERROR_EXPORT_CHECK_IN = 'Der opstod en fejl under eksporteringen af data. Prøv igen senere.';
export const API_ERROR_GET_GROUP_HOME_OTPS = 'Kunne ikke finde officielt tilknyttede personer.';
export const API_ERROR_GET_GROUP_HOME_CHILDREN = 'Kunne ikke finde børn.';
export const API_ERROR_ADD_OTP_TO_GROUP_HOME = 'Kunne ikke tilføje officielt tilknyttet person.';
export const API_ERROR_ADD_OTP_TO_GROUP_HOME_CONFLICT = 'Denne medarbejder er allerede tilknyttet til bostedet.';
export const API_ERROR_ADD_CHILD_TO_GROUP_HOME = 'Kunne ikke tilføje barn.';
export const API_ERROR_ADD_CHILD_TO_GROUP_HOME_CONFLICT = 'Dette barn er allerede tilknyttet til bostedet.';
export const API_ERROR_SET_OTP_AS_ADMIN = 'Kunne ikke opdatere officielt tilknyttet persons administrator rettigheder.';
export const API_ERROR_REMOVING_OTP = 'Den officielt tilknyttede person kunne ikke slettes.';
export const API_ERROR_REMOVING_CHILD = 'Barnet kunne ikke slettes.';
export const API_ERROR_GET_CONTACT_INFO = 'Kontaktinformationerne kunne ikke hentes.';
export const API_ERROR_SETTING_CONTACT_INFO = 'Kontaktinformationerne kunne ikke gemmes.';
export const API_SUCCESS_OTP_ADDED_TO_GROUP_HOME = 'Medarbejderen blev tilføjet til bostedet.';
export const API_SUCCESS_CHILD_ADDED_TO_GROUP_HOME = 'Barnet blev tilføjet til bostedet.';
export const API_SUCCESS_OTP_ADMIN_RIGHTS_CHANGED = 'Administratorrettighederne blev ændret.';
export const API_SUCCESS_OTP_REMOVED_SUCCESSFULLY = 'Medarbejderen er nu fjernet fra bostedet.';
export const API_SUCCESS_CHILD_REMOVED_SUCCESSFULLY = 'Barnet er nu fjernet fra bostedet.';
export const API_SUCCESS_CONTACT_INFO_UPDATED = 'Kontaktinformationerne er nu opdateret.';
export const API_ERROR_SHARE_WITH_OTHER_MUNICIPALITIES =
  'En eller flere af de valgte personer, er fra en anden kommune end et af dokumenterne';
export const API_ERROR_LOAD_OPEN_HOURS = 'Kunne ikke hente åbningstider';
export const API_ERROR_CREATE_DOCUMENT_TEMPLATE = 'Kunne ikke oprette dokumentskabelonen';
export const API_ERROR_GET_DOCUMENT_TEMPLATE = 'Kunne ikke hente dokumentskabelonen';
export const API_ERROR_UPDATE_DOCUMENT_TEMPLATE = 'Kunne ikke opdatere dokumentskabelonen';
export const API_ERROR_DELETE_DOCUMENT_TEMPLATE = 'Kunne ikke slette dokumentskabelonen';
export const API_ERROR_GENERAL = 'Der skete en fejl';

// Print
export const PRINT = 'Udskriv';
export const IMPORTANT = 'Vigtig';
