export const roleEnum = Object.freeze({
  INSTITUTIONAL_ADMINISTRATOR: 0, // Institutional administrator
  SEARCH_USERS_AND_GROUPS: 2, // Search in users and groups
  HANDLE_GROUPS_INSTITUTION: 3, // Handle institutional groups
  HANDLE_GROUPS_MUNICIPALITY: 4, // Handle Inter institutional groups
  HANDLE_USER_ROLES: 5, // Handle user roles
  HANDLE_REPORTS: 6, // Handle reports and delete content
  HANDLE_RESOURCES_INSTITUTION: 8, // Handle Resources
  HANDLE_ADDITIONAL_MASTER_DATA: 9, // Handle additional master data and permissions
  HANDLE_COMMON_FILES: 11, // Handle common files
  HANDLE_SHARED_INBOX: 12, // Handle shared inbox
  MUNICIPAL_ADMINISTRATOR: 13, // Municipal administrator
  HANDLE_RIGHT_TO_INSIGHT: 14, // Handle right to insight and right to be forgotten
  HANDLE_ALLOWED_RECIPIENTS: 15, // Handle allowed recipients
  HANDLE_COMMUNICATION_CHANNELS: 16, // Handle communication channels
  HANDLE_ADMINISTRATIVE_AUTHORITY: 17, // Handle administrative authority
  CENTRAL_ADMINISTRATOR: 20, // Central administration
  BOARD_MEMBER: 22, // Board Member
  COMMUNICATION: 23, // Blocked communication
  ACCESS_TO_COMMON_FILES: 24, // Access to common files
  CREATE_PARENTAL_PERFORMANCE_MEETINGS: 27, // Create parental and performance meetings
  GROUP_MEMBER: 28, // Group member
  WRITE_MESSAGE_GROUP: 29, // Write message to group
  WRITE_POST_GROUP: 30, // Write post to group
  SHARE_MEDIA_WITH_GROUP: 31, // Share media with group
  INVITE_GROUP_TO_EVENT: 32, // Invite group to event
  GROUP_ADMINISTRATOR: 33, // Group administrator
  EMPLOYEE: 34, // Employee
  TEACHER: 35, // Teacher
  PRESCHOOL_TEACHER: 36, // Preschool teacher
  OTHER: 37, // Other
  LEADER: 38, // Leader
  CHILD: 39, // Child
  EARLY_STUDENT: 40, // Early Student
  MIDDLE_LATE_STUDENT: 41, // Middle/late student
  DAYCARE: 42, // Daycare
  AFTER_SCHOOL_STUDENT: 43, // After school student
  GUARDIAN: 44, // Guardian
  HANDLE_TRANSITION_YEAR: 45, // Handle Transition year
  HANDLE_RESOURCES_MUNICIPALITY: 46, // Handle resources on municipality
  CONTACT_PARENT: 47, // Contact Parent
  SEND_SMS: 48, // Send sms
  ACCESS_GOOGLE_DRIVE: 49, // Access Google Drive
  ACCESS_ONEDRIVE: 50, // Access OneDrive
  HANDLE_DASHBOARDS: 51, // Handle dashboards
  IMPERSONATE_OTHER_USER: 52, // Access to impersonating other users
  PRESENCE_PLANNING: 53, // Able to request vacation
  HANDLE_PRESENCE: 54, // Able to update settings for presence dashboard
  USE_GROUPS_AS_DISTRIBUTION_LISTS: 55, // Use groups as distribution lists
  VIEW_NAME_PROTECTION: 56, // View name protection
  SECURE_DOCUMENTS_ACCESS_ALL: 61, // Access to all secure documents within an institution
  AULA_DEVELOPER: 62, // Access to developer tools in administration module
  ASSOCIATE_SECURE_DOCUMENTS_TO_ALL_GROUPS: 63, // Access to developer tools in administration module
  INSTITUTION_CALENDAR_ADMIN: 64, // Allows extra functionality for the Institution Calendar.
  VIEW_PERSONAL_REFERENCE_DATA_FOR_ALL_CHILDREN_AND_GUARDIAN: 65, // View personal reference data for all children and guardians on institution
  VIEW_CONTACT_INFORMATION_ALL: 66, // Allows employee to see contact lists regarding users in groups they are not a member of on institution
  EXPORT_SECURE_FILES: 68, // Allows employees to export secure files
  HANDLE_OTHERS_EVENTS: 69, // Handle other user's events
  EDIT_SHARED_ALBUMS: 70, // Permission to edit albums that you have been invited to
  HANDLE_DELETED_SECURE_FILES: 71,
});
