import { messageOwnerTypes } from '../enums/messageOwnerTypes';
import { messageTypes } from '../enums/messageTypes';
import { MessageRecipientModel } from '../models/messageRecipient.model';
import { MessageThreadModel } from '../models/messageThread.model';
import { CommonInboxModel } from '../models/commonInbox.model';
import { commonInboxType } from '../enums/commonInboxType';
import { groupSearchScopes } from '../enums/groupSearchScopes.js';
import { MessageBoxModel } from '../models/messageBox.model';
import { InstitutionProfileModel } from '../models/institutionProfile.model';
import { RecipientModel } from '../models/recipient.model';
import { docTypes } from '../enums/docTypes';

class MessageUtil {
  getFirstParticipant(recipients: MessageRecipientModel[], chosenFolderAndMailOwner: any, profile: any) {
    const isCurrentCommonOrOtpInbox = recipient =>
      (this.getIsRecipientCommonInbox(recipient) || this.getIsRecipientOtpInbox(recipient)) &&
      recipient.mailBoxOwner.id === chosenFolderAndMailOwner?.mailOwnerId;
    const isCurrentUserInbox = recipient =>
      chosenFolderAndMailOwner?.mailOwnerId == null &&
      this.getIsRecipientInstitutionProfile(recipient) &&
      recipient.mailBoxOwner?.profileId === profile.profileId;

    for (const recipient of recipients) {
      if (isCurrentCommonOrOtpInbox(recipient) || isCurrentUserInbox(recipient)) {
        continue;
      }
      return recipient;
    }
    return null;
  }

  getExtraRecipientsCount(subscription: MessageThreadModel) {
    const { recipients, extraRecipientsCount } = subscription;
    const activeRecipients = recipients.filter(
      recipient =>
        recipient.leaveTime === null &&
        !(
          recipient.mailBoxOwner.id === subscription.mailBoxOwner.id &&
          recipient.mailBoxOwner.mailBoxOwnerType === subscription.mailBoxOwner.mailBoxOwnerType
        )
    );

    if (activeRecipients.length === 0) {
      return 0;
    }
    return activeRecipients.length - 1 + extraRecipientsCount;
  }

  getIsRecipientCommonInbox(recipient: MessageRecipientModel) {
    return recipient.mailBoxOwner?.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX;
  }

  getIsRecipientOtpInbox(recipient: MessageRecipientModel) {
    return recipient.mailBoxOwner?.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX;
  }

  getIsRecipientInstitutionProfile(recipient: MessageRecipientModel) {
    return recipient.mailBoxOwner?.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE;
  }

  getIsSelectableMessageType(messageType: messageTypes) {
    const selectableMessageTypes = [
      messageTypes.MESSAGE,
      messageTypes.MESSAGE_EDITED,
      messageTypes.FORWARD,
      messageTypes.AUTO_REPLY,
    ];
    return selectableMessageTypes.includes(messageType);
  }

  getGroupSearchScope(selectedInbox: MessageBoxModel, commonInboxes: CommonInboxModel[]) {
    let groupSearchScope = null;
    const commonInbox = commonInboxes.find(
      inbox => inbox.id === selectedInbox?.value && selectedInbox?.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
    );
    if (commonInbox?.commonInboxType === commonInboxType.INSTITUTIONAL) {
      groupSearchScope = groupSearchScopes.INSTITUTIONAL;
    } else if (commonInbox?.commonInboxType === commonInboxType.CROSS_INSTITUTIONAL) {
      groupSearchScope = groupSearchScopes.CROSS_INSTITUTIONAL;
    }
    return groupSearchScope;
  }

  mapInstitutionProfileToRecipient(profile: InstitutionProfileModel): RecipientModel {
    return {
      id: profile.id,
      profileId: profile.profileId,
      mailBoxOwnerType: messageOwnerTypes.INSTITUTION_PROFILE,
      name: profile.fullName,
      metadata: profile.metadata,
      label: `${profile.fullName} (${profile.metadata})`,
      institutionCode: profile.institutionCode,
      type: docTypes.PROFILE.toLowerCase(),
      portalRole: profile.role,
      value: docTypes.PROFILE.toLowerCase() + profile.id + profile.institutionCode,
    };
  }
}

export const messageUtil = new MessageUtil();
