import { types } from '../../../src/store/types/types';

const state = {
  institutionPermissions: {},
  groupPermissions: {},
};

const getters = {
  [types.HAS_PERMISSION]: state => permission => {
    for (const institutionCode in state.institutionPermissions) {
      if (state.institutionPermissions[institutionCode][permission]) {
        return true;
      }
    }
    return false;
  },
  [types.HAS_PERMISSION_ON_INSTITUTION]: state => (permission, institutioncode) => {
    const institution = state.institutionPermissions[institutioncode];
    if (institution !== undefined && institution[permission] !== undefined) {
      return !!institution[permission].institutionscope;
    }
    return false;
  },
  [types.HAS_PERMISSION_ON_GROUP]: state => (permission, groupId) => {
    if (state.groupPermissions[permission] != null && state.groupPermissions[permission].groupscope != null) {
      return !!state.groupPermissions[permission].groupscope[groupId];
    }
    return false;
  },
  [types.HAS_PERMISSION_ON_INSTITUTION_GROUP]:
    state =>
    ({ permissionId, institution, group }) => {
      const institutionCode = institution.institutionCode;
      const groupId = group.id;
      return !!state.institutionPermissions[institutionCode][permissionId]?.groupscope[groupId];
    },
};

const mutations = {
  [types.MUTATE_INIT_PERMISSIONS]: (state, { institutions }) => {
    const institutionsDictionary = {};
    state.groupPermissions = {};
    for (const institution of institutions) {
      const permissionDictionary = {};
      for (const permission of institution.permissions) {
        const groupScopeDictionary = {};
        const groupScopes = permission.groupScopes;
        const groupPermissionState = state.groupPermissions[permission.permissionId] || { groupscope: {} };

        for (const groupId of groupScopes) {
          groupScopeDictionary[groupId] = true;
          groupPermissionState.groupscope[groupId] = true;
        }

        permissionDictionary[permission.permissionId] = {
          institutionscope: permission.institutionScope,
          groupscope: groupScopeDictionary,
        };
        state.groupPermissions[permission.permissionId] = groupPermissionState;
      }
      institutionsDictionary[institution.institutionCode] = permissionDictionary;
    }
    state.institutionPermissions = institutionsDictionary;
  },
};

const actions = {
  [types.INIT_PERMISSIONS]: ({ commit, rootState }) => {
    commit(types.MUTATE_INIT_PERMISSIONS, { institutions: rootState.globalShared.institutions });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
