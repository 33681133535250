import { types } from '../../../src/store/types/types';
import { store } from '../../../src/store/store';

import { portal } from '../../assets/plugins/axios/axios';
import moment from 'moment-timezone';
import { Cookie } from '../../assets/plugins/cookie';
import { parentTypes } from '../../enums/parentTypes';
import { errorSubCodeTypes } from '../../enums/errorSubCodeTypes';
import { breakpoints } from '../../enums/breakpoints';
import uniqBy from 'lodash/uniqBy';

const state = {
  isMobile: window.innerWidth <= breakpoints.MOBILE_BREAKPOINT,
  isMobileDevice: typeof window.orientation !== 'undefined',
  isTablet: window.innerWidth <= breakpoints.TABLET_BREAKPOINT && window.innerWidth > breakpoints.MOBILE_BREAKPOINT,
  clientHeight: screen.height,
  clientWidth: screen.width,
  contentWrapperHeight: null,
  date: moment().date() + '-' + (moment().month() + 1) + '-' + moment().year(),
  time: moment().hour() + ':' + moment().minute(),
  currentProfile: { id: 0, institutionRoles: [] },
  profileLoaded: false,
  selectedInstitution: {},
  activeProfile: null,
  showSpinner: false,
  institutions: [],
  maxFileSize: null,
  isSteppedUp: false,
  errorText: '',
  successText: '',
  debugApiError: null,
  configuration: {},
  userGroups: [],
  profiles: [],
  hasSupportRole: false,
  isMunicipalAdministrator: false,
  portalRoles: [],
  keepAlive: '',
  blockedCommunicationProfiles: {},
  toastTimer: 0,
  openModals: 0,
  oneDayInMiliseconds: 86400000,
  isImpersonationMode: false,
  defaultDateFormat: 'dd/MM/yyyy',
  defaultDateValueFormat: 'yyyy-MM-dd',
  defaultTimeFormat: 'HH:mm',
  defaultTimeValueFormat: 'HH:mm',
  defaultDateOptions: {
    disabledDate(time) {
      return time.getTime() + state.oneDayInMiliseconds < moment().toDate().getTime();
    },
    firstDayOfWeek: 1,
  },
  pageConfiguration: {},
  sessionId: null,
  dataPolicy: {},
  openDataPolicy: {},
  documentVisibleState: 'visible',
};

const getters = {
  [types.GET_OPEN_MODALS]: state => state.openModals,
  [types.GET_KEEP_ALIVE]: state => state.keepAlive,
  [types.GET_ERROR_TEXT]: state => state.errorText,
  [types.GET_DEBUG_API_ERROR]: state => state.debugApiError,
  [types.GET_SUCCESS_TEXT]: state => state.successText,
  [types.IS_PROFILE_LOADED]: state => state.profileLoaded,
  [types.GET_SHOW_SPINNER]: state => state.showSpinner,
  [types.GET_CLIENT_HEIGHT]: state => state.clientHeight,
  [types.GET_CONTENT_WRAPPER_HEIGHT]: state => {
    if (!state.contentWrapperHeight && document.getElementsByClassName('content-wrapper')[0] != null) {
      state.contentWrapperHeight = document.getElementsByClassName('content-wrapper')[0].clientHeight;
    }
    return state.contentWrapperHeight;
  },
  [types.GET_CLIENT_WIDTH]: state => state.clientWidth,
  [types.GET_DATE]: state => state.date,
  [types.GET_TIME]: state => state.time,
  [types.GET_CURRENT_PROFILE]: state => state.currentProfile,
  [types.GET_USER_GROUPS]: state => state.userGroups,
  [types.GET_INSTITUTIONS]: state => state.institutions,
  [types.GET_IS_MOBILE]: state => state.isMobile,
  [types.GET_IS_MOBILE_DEVICE]: state => state.isMobileDevice,
  [types.GET_IS_TABLET]: state => state.isTablet,
  [types.GET_GLOBAL_STEPPED_UP]: state => state.isSteppedUp,
  [types.GET_SELECTED_INSTITUTION]: state => state.selectedInstitution,
  [types.GET_ACTIVE_PROFILE]: state => state.activeProfile,
  [types.GET_PROFILES_BY_LOGIN]: state => state.profiles,
  [types.GET_BLOCKED_COMMUNICATION_PROFILES]: state => state.blockedCommunicationProfiles,
  [types.GET_TOAST_TIMER]: state => state.toastTimer,
  [types.GET_IS_IMPERSONATION_MODE]: state => state.isImpersonationMode,
  [types.GET_HAS_SUPPORT_ROLE]: state => state.hasSupportRole,
  [types.GET_DEFAULT_DATE_FORMAT]: state => state.defaultDateFormat,
  [types.GET_DEFAULT_DATE_VALUE_FORMAT]: state => state.defaultDateValueFormat,
  [types.GET_DEFAULT_TIME_FORMAT]: state => state.defaultTimeFormat,
  [types.GET_DEFAULT_TIME_VALUE_FORMAT]: state => state.defaultTimeValueFormat,
  [types.GET_DEFAULT_DATE_OPTIONS]: state => state.defaultDateOptions,
  [types.GET_PAGE_CONFIGURATION]: state => state.pageConfiguration,
  [types.GET_EDITOR_PLUGIN_DETAILS]: state => state.pageConfiguration.editorPluginDetails,
  [types.GET_PHP_SESSION_ID]: state => state.sessionId,
  [types.GET_PROFILE_CHANGE]: state => state.sessionId,
  [types.GET_DATA_POLICY]: state => state.dataPolicy,
  [types.GET_OPEN_DATA_POLICY]: state => state.openDataPolicy,
  [types.GET_IS_DOCUMENT_STATE_VISIBLE]: state => state.documentVisibleState === 'visible',
  [types.GET_IS_MUNICIPAL_ADMINISTRATOR]: state => state.isMunicipalAdministrator,
};

const mutations = {
  [types.MUTATE_DISABLE_IMPERSONATION_MODE]: () => {
    Cookie.Erase('hasLoadedImpersonateProfile');
    Cookie.Erase('isImpersonationMode');
    Cookie.Erase('impersonateId');
    Cookie.Erase('reason');
    Cookie.Erase('caseId');
  },
  [types.MUTATE_KEEP_ALIVE]: (state, payload) => {
    state.keepAlive = payload;
  },
  [types.MUTATE_IS_MOBILE]: state => {
    setTimeout(() => {
      state.isMobile = window.innerWidth <= breakpoints.MOBILE_BREAKPOINT;
    }, 500);
  },
  [types.MUTATE_IS_TABLET]: state => {
    state.isTablet =
      window.innerWidth <= breakpoints.TABLET_BREAKPOINT && window.innerWidth > breakpoints.MOBILE_BREAKPOINT;
  },
  [types.MUTATE_ADD_OPEN_MODAL]: state => {
    state.openModals++;
  },
  [types.MUTATE_SET_IMPERSONATION_MODE]: (state, bool) => (state.isImpersonationMode = bool),
  [types.MUTATE_SUBTRACT_OPEN_MODAL]: state => {
    if (state.openModals > 0) {
      state.openModals--;
    }
  },
  [types.MUTATE_ERROR_TEXT]: (state, payload) => {
    state.errorText = payload;
  },
  [types.MUTATE_DEBUG_API_ERROR]: (state, payload) => {
    state.debugApiError = payload;
  },
  [types.MUTATE_SUCCESS_TEXT]: (state, payload) => {
    state.successText = payload;
  },
  [types.MUTATE_PROFILE_PICTURE]: (state, payload) => {
    if (state.currentProfile.id == payload.institutionProfileId) {
      if (payload.media != null && payload.media.url != null) {
        state.currentProfile.profilePicture = { url: payload.media.url };
      } else {
        state.currentProfile.profilePicture = null;
      }
    }
    for (const child of payload.children) {
      if (child.id == payload.institutionProfileId) {
        if (payload.media != null && payload.media.url != null) {
          child.profilePicture = { url: payload.media.url };
        } else {
          child.profilePicture = null;
        }
      }
    }
  },
  [types.MUTATE_SET_CURRENT_PROFILE]: (state, payload) => {
    const profile = payload.profile;
    const institutionProfile = profile.institutionProfile;
    institutionProfile.profileId = profile.id;
    institutionProfile.institutionCode = profile.institutionProfile.institution.institutionCode;
    institutionProfile.id = profile.institutionProfile.id.toString();
    institutionProfile.supportRole = profile.supportRole;
    institutionProfile.loginPortalRole = profile.loginPortalRole;
    institutionProfile.isGroupHomeAdmin = payload.profile.isGroupHomeAdmin;
    institutionProfile.groupHomes = profile.groupHomes;

    if (profile.supportRole) {
      state.hasSupportRole = true;
    }
    state.isMunicipalAdministrator = Boolean(profile.municipalAdmin);
    state.institutions = [];
    state.userGroups = [];
    institutionProfile.institutionRoles = [];
    for (const institution of profile.institutions) {
      institution.isPrimary = institution.institutionCode === institutionProfile.institution.institutionCode;
      state.institutions.push(institution);
      for (const group of institution.groups) {
        group.institutionCode = institution.institutionCode;
        group.institutionName = institution.name;
      }
      state.userGroups = state.userGroups.concat(institution.groups);
      institutionProfile.institutionRoles.push(institution.institutionRole);
    }
    const primaryInstitutionIndex = state.institutions.findIndex(i => i.isPrimary);
    if (primaryInstitutionIndex > 0) {
      state.institutions.unshift(state.institutions[primaryInstitutionIndex]);
      state.institutions.splice(primaryInstitutionIndex + 1, 1);
    }

    if (profile.municipalGroups?.length > 0) {
      state.userGroups = state.userGroups.concat(profile.municipalGroups);
    }

    state.userGroups = uniqBy(state.userGroups, group => group.id);
    state.userGroups.sort((a, b) => a.name.localeCompare(b.name, 'da', { sensitivity: 'base' }));
    state.pageConfiguration = profile.pageConfiguration;
    state.currentProfile = institutionProfile;
    state.selectedInstitution = profile.institutions[0];
    state.activeProfile = profile.institutions[0];
    state.profileLoaded = true;
    state.isSteppedUp = payload.profile.isSteppedUp;
  },
  [types.MUTATE_SET_STEPPED_UP]: (state, payload) => {
    state.isSteppedUp = payload;
  },
  [types.MUTATE_SET_PROFILES_BY_LOGIN]: (state, payload) => {
    sessionStorage.clear();
    state.profiles = payload.profiles;
  },
  [types.MUTATE_TOGGLE_SPINNER]: state => {
    if (state.showSpinner) {
      setTimeout(function () {
        state.showSpinner = !state.showSpinner;
      }, 1500);
    } else {
      state.showSpinner = !state.showSpinner;
    }
  },
  [types.MUTATE_SET_SELECTED_INSTITUTION]: (state, payload) => {
    state.selectedInstitution = payload;
  },
  [types.MUTATE_SET_ACTIVE_PROFILE]: (state, profile) => {
    state.activeProfile = profile;
  },
  [types.MUTATE_REMOVE_GROUP_FROM_USER_GROUPS]: (state, payload) => {
    state.userGroups = state.userGroups.filter(group => group.id != payload);
  },
  [types.MUTATE_SET_BLOCKED_COMMUNICATION_PROFILES]: (state, payload) => {
    state.blockedCommunicationProfiles = payload.data;
    if (payload.institutionCode) {
      state.blockedCommunicationProfiles.institutionCode = payload.institutionCode;
    }
  },
  [types.MUTATE_SET_TOAST_TIMER]: (state, payload) => {
    state.toastTimer = payload;
  },
  [types.MUTATE_SET_MEMBERSHIP]: (state, payload) => {
    state.userGroups = state.userGroups.filter(group => group.id != payload.groupId);
  },
  [types.MUTATE_ADD_GROUP]: (state, payload) => {
    if (state.userGroups.filter(group => group.id == payload.id).length === 0) {
      state.userGroups.push(payload);
    }
  },
  [types.MUTATE_PHP_SESSION_ID]: (state, payload) => {
    state.sessionId = payload;
  },
  [types.MUTATE_PROFILE_CHANGE]: (state, payload) => {
    state.sessionId = payload;
  },
  [types.MUTATE_DATA_POLICY]: (state, payload) => {
    state.dataPolicy = payload;
  },
  [types.MUTATE_OPEN_DATA_POLICY]: (state, payload) => {
    state.openDataPolicy = payload;
  },
  [types.MUTATE_SET_DOCUMENT_VISIBLE_STATE]: (state, documentVisibleState) => {
    state.documentVisibleState = documentVisibleState;
  },
};

const actions = {
  [types.ACTION_GET_PROFILE_CONTEXT]: ({ commit }, payload) => {
    Cookie.Erase('fromAdminGroup');
    let params = null;
    if (payload && payload.portalrole) {
      params = { params: { portalrole: payload.portalrole } };
    }
    if (location.href.search('/widgetMobile/') == -1) {
      const newVal = parseInt(Cookie.Read('profile_change')) + 1;
      Cookie.Create('profile_change', newVal, 365);
      store.commit(types.MUTATE_PROFILE_CHANGE, newVal);
    }
    return portal
      .get('?method=profiles.getProfileContext', params)
      .then(response => {
        if (payload != null && payload.parent === parentTypes.PORTAL) {
          commit(types.MUTATE_SET_ACTIVE_ENTITIES_FOR_CURRENT_PROFILE, {
            profile: response.data.data,
          });
        }
        commit(types.MUTATE_MESSAGES_RESET_STATE);
        commit(types.MUTATE_SET_CURRENT_PROFILE, {
          profile: response.data.data,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PROFILE_CONTEXT');
      });
  },
  [types.EMPLOYEE_LOGIN_CHECKIN_DASHBOARD]: (_, payload) =>
    (window.location.href =
      '/api/' +
      window.aulaApiVersion +
      '/?method=presence.loginInstitutionDevice&institutionCode=' +
      payload.institutionCode),
  [types.SWITCH_CURRENT_PROFILE]: ({ commit, rootState }) =>
    portal
      .get('?method=profiles.getProfileByProfileCtx')
      .then(response =>
        commit(types.MUTATE_SET_CURRENT_PROFILE, {
          profile: response.data.data,
          global: rootState.global,
        })
      )
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PROFILE_CONTEXT');
      }),
  [types.LOAD_PROFILES_BY_LOGIN]: ({ commit }) =>
    portal
      .get('?method=profiles.getProfilesByLogin')
      .then(response => {
        if (Cookie.Read('profile_change') == null) {
          Cookie.Create('profile_change', 1, 365);
        }
        return commit(types.MUTATE_SET_PROFILES_BY_LOGIN, response.data.data);
      })
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PROFILE_CONTEXT');
        return error;
      }),
  [types.TOGGLE_SPINNER]: ({ commit }, payload) => {
    if (!payload.promises || !payload.promises.length) {
      return Promise.resolve();
    }
    // toggle spinner on
    commit(types.MUTATE_TOGGLE_SPINNER);
    return Promise.all(payload.promises)
      .then(function () {
        commit(types.MUTATE_TOGGLE_SPINNER);
      })
      .catch(function (error) {
        commit(types.MUTATE_TOGGLE_SPINNER);
        throw error;
      });
  },
  [types.ACTIONS_SESSION_STEP_UP]: ({ commit }) =>
    portal.post('?method=session.stepUpCurrentSession', {}).then(
      response =>
        new Promise((resolve, reject) => {
          if (response.data.data) {
            commit(types.MUTATE_SET_STEPPED_UP, true);
            resolve(response.data.data);
          } else {
            reject(new Error('request-failed'));
          }
        })
    ),
  [types.CLEAR_ERROR_TEXT]: ({ commit }) => {
    commit(types.MUTATE_ERROR_TEXT, '');
  },
  [types.SET_SELECTED_INSTITUTION]: ({ commit }, payload) => {
    commit(types.MUTATE_SET_SELECTED_INSTITUTION, payload);
  },
  [types.REMOVE_GROUP_FROM_USER_GROUPS]: ({ commit }, payload) => {
    commit(types.MUTATE_REMOVE_GROUP_FROM_USER_GROUPS, payload);
  },
  [types.ACTION_KEEPALIVE]: ({ commit }) =>
    portal.get('?method=session.keepAlive', {}).catch(() => {
      commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_KEEP_SESSION_ALIVE');
    }),
  [types.SET_BLOCKED_COMMUNICATION_PROFILES]: ({ commit }, payload) => {
    const params = { institutionCode: payload };
    return portal
      .get('?method=municipalConfiguration.getBlockedCommunicationProfiles', {
        params: params,
      })
      .then(response => {
        const responseVal = response.data;
        if (payload) {
          responseVal.institutionCode = payload;
        }
        commit(types.MUTATE_SET_BLOCKED_COMMUNICATION_PROFILES, responseVal);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_BLOCKED_COMMUNICATION_PROFILES');
      });
  },
  [types.IMPERSONATE_PROFILE]: ({ commit, rootState }, payload) =>
    portal
      .get('?method=profiles.impersonateUser', { params: payload })
      .then(response => {
        commit(types.MUTATE_SET_ACTIVE_ENTITIES_FOR_CURRENT_PROFILE, {
          profile: response.data.data,
          global: rootState.global,
        });
        commit(types.MUTATE_SET_CURRENT_PROFILE, {
          profile: response.data.data,
          global: rootState.global,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_IMPERSONATE_USER');
      }),
  [types.DISABLE_IMPERSONATION_MODE]: ({ commit }) => {
    commit(types.MUTATE_DISABLE_IMPERSONATION_MODE);
  },
  [types.END_IMPERSONATION_MODE]: ({ commit, rootState }) => {
    portal
      .get('?method=profiles.endImpersonation')
      .then(response => {
        commit(types.MUTATE_DISABLE_IMPERSONATION_MODE);
        commit(types.MUTATE_SET_ACTIVE_ENTITIES_FOR_CURRENT_PROFILE, {
          profile: response.data.data,
          global: rootState.global,
        });
        commit(types.MUTATE_SET_CURRENT_PROFILE, {
          profile: response.data.data,
          global: rootState.global,
        });
        window.location.reload();
      })
      .catch(error => {
        if (error.response.data.status.subCode == errorSubCodeTypes.notImpersonating) {
          commit(types.MUTATE_DISABLE_IMPERSONATION_MODE);
          window.location.reload();
        }
      });
  },
  [types.LOAD_DATA_POLICY]: ({ commit }) =>
    portal
      .get('?method=CentralConfiguration.getDataPolicy')
      .then(response => {
        commit(types.MUTATE_DATA_POLICY, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_DATA_POLICY');
      }),
  [types.LOAD_OPEN_DATA_POLICY]: ({ commit }) =>
    portal
      .get('?method=CentralConfiguration.getOpenDataPolicy')
      .then(response => {
        commit(types.MUTATE_OPEN_DATA_POLICY, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_DATA_POLICY');
      }),
  [types.SET_DOCUMENT_VISIBLE_STATE]: ({ commit }, documentVisibleState) => {
    commit(types.MUTATE_SET_DOCUMENT_VISIBLE_STATE, documentVisibleState);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
